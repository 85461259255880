import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { UserInterface } from '@interfaces/user/user.interface';
import { AbstractAuthService } from '@services/abstract/abstract.auth.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export abstract class AbstractAuthGuard {
  constructor(
    protected authService: AbstractAuthService,
    protected router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.checkUserLogin(route, state);
  }

  checkUserLogin(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.getObservable('storeLoaded').pipe(
      filter((storeLoaded) => !!storeLoaded),
      take(1),
      switchMap(() =>
        this.authService.getObservable().pipe(
          map((user: UserInterface) => {
            return this.check(user, state);
          }),
          catchError((err) => {
            return of(this.onError(err));
          }),
        ),
      ),
    );
  }

  protected abstract check(
    user: UserInterface,
    state: RouterStateSnapshot,
  ): boolean;

  protected abstract onError(error): boolean;
}
