import { Injectable } from '@angular/core';
import { ActivityInterface } from '@interfaces/activities/activities.interface';
import { ActivitiesQueryParams } from '@interfaces/activities/activities.queryParams.interface';
import { CategoriesApi } from '@interfaces/categories/categories.enum';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ActivitiesService extends AbstractService<
  any,
  ActivitiesQueryParams
> {
  protected errorsSection = 'errors.activities';
  protected endPoint = '/activities/';

  public filterAvailableActivities(
    activities: ActivityInterface[],
  ): ActivityInterface[] {
    return activities.filter((activity) => {
      const checkSchedules = [
        CategoriesApi.PLANSOFTHEDAY,
        CategoriesApi.BIRTHDAYS,
      ].includes(activity.category);

      const available: boolean = checkSchedules
        ? activity.sessions.findIndex(
            (session) =>
              session.schedules.findIndex(
                (schedule) => schedule.availablePlaces > 0,
              ) !== -1,
          ) !== -1
        : activity.sessions.findIndex(
            (session) => session.availablePlaces > 0,
          ) !== -1;
      return available;
    });
  }

  public updateValoration(
    activityId: string,
    valoration: number,
    section: string,
  ): void {
    const endPoint = `${this.endPoint}${activityId}/valoration`;
    this.update('', { valoration }, section, [], endPoint);
  }
}
