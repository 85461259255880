import { Component, OnInit } from '@angular/core';
import { PolicyType } from '@interfaces/policies/policies.interface';
import { UserInterface } from '@interfaces/user/user.interface';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { ModalService } from '@services/modal/modal.service';
import { PoliciesService } from '@services/policies/policies.service';
import { Observable, map, take } from 'rxjs';
import { updateObservable } from '@shared/utils/helpers/basic-observable.helper';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { PublicObservableSections } from '@app/modules/public/constants/public.basic-ob.sections';
import { LogoutMessageModalComponent } from '@shared/components/logout-message-modal/logout-message-modal.component';

enum LinkType {
  URL = 'url', // with href
  FILE = 'file', // for files
  MAIL = 'mail', // for mails
  ROUTE = 'route', // for angular routing
  BUTTON = 'button', // for buttons
}

interface menuItems {
  category: string;
  links: {
    title: string;
    link?: string;
    blank?: boolean;
    type?: LinkType;
    onClick?: (event: MouseEvent) => void;
  }[];
}

@Component({
  selector: 'app-menu-modal',
  templateUrl: 'menu-modal.template.html',
})
export class MenuModalComponent implements OnInit {
  public isLogged: Observable<boolean> = this.authService
    .getObservable()
    .pipe(map((user: UserInterface) => !!user && !!user.id));

  public menuItems: menuItems[] = [
    {
      category: 'public.modal-menu.becubs',
      links: [
        {
          title: 'public.modal-menu.knowus',
          link: 'public/about-us',
          type: LinkType.ROUTE,
        },
        {
          title: 'public.modal-menu.contact',
          link: 'public/contact',
          type: LinkType.ROUTE,
        },
        {
          title: 'public.modal-menu.blog',
          link: 'https://medium.com/@admin_49077',
          blank: true,
        },
      ],
    },
    {
      category: 'public.modal-menu.help',
      links: [
        {
          title: 'public.modal-menu.faqs',
          link: 'public/faqs',
          type: LinkType.ROUTE,
        },
        {
          title: 'public.modal-menu.legalwarning',
          link: '#',
          blank: true,
          type: LinkType.FILE,
        },
        {
          title: 'public.modal-menu.terms',
          link: '#',
          blank: true,
          type: LinkType.FILE,
        },
        {
          title: 'public.modal-menu.termsAcademy',
          link: '#',
          blank: true,
          type: LinkType.FILE,
        },
        {
          title: 'public.modal-menu.privacypolicy',
          link: '#',
          blank: true,
          type: LinkType.FILE,
        },
        {
          title: 'public.modal-menu.cookies',
          link: '#',
          blank: true,
          type: LinkType.FILE,
        },
      ],
    },
    {
      category: 'public.modal-menu.appear',
      links: [
        {
          title: 'public.modal-menu.showplan',
          type: LinkType.BUTTON,
          onClick: () => this.knowMore(),
        },
      ],
    },
  ];

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private navCtrl: NavController,
    private policiesService: PoliciesService,
    private modalService: ModalService,
    private bOBService: BasicObservableService,
  ) {}

  ngOnInit() {
    this.setPolicies();
  }

  public get LinkType() {
    return LinkType;
  }

  private async setPolicies(): Promise<void> {
    const currentLang = this.translate.currentLang;

    this.menuItems[1].links[1].link = await this.policiesService.getPolicy(
      PolicyType.LEGAL_ADVERTISEMENT,
      currentLang,
    );
    this.menuItems[1].links[2].link = await this.policiesService.getPolicy(
      PolicyType.USER_TERMS_AND_CONDITIONS,
      currentLang,
    );

    this.menuItems[1].links[3].link = await this.policiesService.getPolicy(
      PolicyType.ACADEMY_TERMS_AND_CONDITIONS,
      currentLang,
    );

    this.menuItems[1].links[4].link = await this.policiesService.getPolicy(
      PolicyType.PRIVACY,
      currentLang,
    );

    this.menuItems[1].links[5].link = await this.policiesService.getPolicy(
      PolicyType.COOKIES,
      currentLang,
    );
  }

  logout() {
    this.navCtrl.navigateRoot('/public');
    this.authService.logout();
    this.closeMenu();
  }

  closeMenu() {
    this.modalCtrl.dismiss(null, 'close');
  }

  public onLinkClick(
    event: MouseEvent,
    cb?: (event: MouseEvent) => void,
  ): void {
    this.closeMenu();
    if (cb) {
      cb(event);
    }
  }

  knowMore(): void {
    if (!this.authService.isLogged) {
      updateObservable(
        this.bOBService,
        PublicObservableSections.SHOW_MODAL,
        'academysignup',
      );
    } else if (this.authService.currentUser.isAcademy) {
      this.navCtrl.navigateRoot('admin/academy/management/activities');
    } else {
      this.showLogoffModal();
    }
  }

  private async showLogoffModal(): Promise<void> {
    await this.modalService.openModal(
      'familiar-modal',
      LogoutMessageModalComponent,
      'modal modal--user',
      {
        titleKey: 'public.messages.work_with_you',
        messageKeys: ['public.messages.onlynousercansignup'],
        openLoginWhenDismiss: false,
      },
      ({ data }) => {
        if (!!data?.logOff) {
          updateObservable(
            this.bOBService,
            PublicObservableSections.SHOW_MODAL,
            'academysignup',
          );
        }
      },
    );
  }
}
