<div class="modal-body modal-presignup">
  <span class="modal-content__title" translate>public.modal-user.title</span>

  <div class="pre-signup mt-30">
    <div class="pre-signup__block">
      <div class="pre-signup__description">
        <span translate>public.modal-user.registerfamily</span>
        <svg-icon
          class="pre-signup__image"
          src="/assets/img/icons/family.svg"
        ></svg-icon>
      </div>
      <ion-button
        (click)="goSignup(signupType.SIGNUP_FAMILY)"
        class="button button--normal button--login ion-align-self-center"
        translate
        >session.signup</ion-button
      >
    </div>

    <div class="pre-signup__block">
      <div class="pre-signup__description">
        <span translate>public.modal-user.registeracademy</span>
        <svg-icon
          class="pre-signup__image"
          src="/assets/img/icons/crossword.svg"
        ></svg-icon>
      </div>
      <ion-button
        (click)="goSignup(signupType.SIGNUP_ACADEMY)"
        class="button button--normal button--login ion-align-self-center"
        translate
        >public.modal-user.becubsforbusiness</ion-button
      >
    </div>
  </div>
</div>
