import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IonicToasterService } from './ionic-toaster.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [TranslateModule, CommonModule],
})
export class IonicToasterModule {
  static forRoot(): ModuleWithProviders<IonicToasterModule> {
    return {
      ngModule: IonicToasterModule,
      providers: [IonicToasterService],
    };
  }
}
