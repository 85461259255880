import { isPlatform } from "@ionic/angular";

enum PlatformEnum {
  MOBILE = 'mobile',
  DESKTOP = 'desktop'
}

const getPlatform = (): PlatformEnum => isPlatform('mobileweb') 
  ? PlatformEnum.MOBILE 
  : PlatformEnum.DESKTOP

const isMobile = (): boolean => getPlatform() === PlatformEnum.MOBILE

export {
  PlatformEnum,
  getPlatform,
  isMobile
}