import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { ActivitiesService } from './activities.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class ActivitiesServiceModule {
  static forRoot(): ModuleWithProviders<ActivitiesServiceModule> {
    return {
      ngModule: ActivitiesServiceModule,
      providers: [ActivitiesService],
    };
  }
}
