import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switcher',
  templateUrl: 'language-switcher.template.html',
})
export class LanguageSwitcherComponent implements OnInit {
  public languages: string[] = ['es', 'en'];

  constructor(public translate: TranslateService) {}

  ngOnInit() {}

  changeLanguage(language: string): void {
    this.translate.use(language);
  }
}
