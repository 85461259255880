import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceInterface } from '@interfaces/user/device/device.model.interface';
import { DeviceQueryParamsInterface } from '@interfaces/user/device/device.queryParams.interface';
import { UserInterface } from '@interfaces/user/user.interface';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FCM, NotificationData } from '@awesome-cordova-plugins/fcm/ngx';
// import { KeychainTouchId } from '@ionic-native/keychain-touch-id/ngx';
import { Platform } from '@ionic/angular';
import { AbstractService } from '@services/abstract/abstract.service';
import { AuthService } from '@services/auth/auth.service';
import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';
import { ErrorsService } from '@services/errors/errors.service';

@Injectable()
export class DeviceService extends AbstractService<
  DeviceInterface,
  DeviceQueryParamsInterface
> {
  protected errorsSection = 'errors.devices';
  protected endPoint = '/users/%s/devices/';
  protected device: DeviceInterface;
  protected isMobileApp = true;
  protected user: UserInterface;

  constructor(
    protected readonly http: HttpClient,
    protected readonly errorsService: ErrorsService,
    protected readonly platform: Platform,
    protected readonly deviceService: Device,
    protected readonly fcm: FCM,
    protected readonly appVersion: AppVersion,
    protected readonly basicObservableService: BasicObservableService, // protected readonly keychainTouchId: KeychainTouchId,
    protected readonly authService: AuthService,
  ) {
    super(http, errorsService);
    this.user = authService.currentUser;
    this.isMobileApp = !(
      this.platform.is('mobileweb') || this.platform.is('desktop')
    );

    // if (this.isMobileApp) {
    //   this.onNotification();
    // }
  }

  public async getDeviceInfo(): Promise<DeviceInterface> {
    if (!this.isMobileApp) {
      return null;
    }

    if (this.device) {
      return this.device;
    }

    let pushToken = await this.fcm.getToken();
    if (!pushToken) {
      pushToken = await this.fcm.onTokenRefresh().toPromise();
    }

    const checkPlatform: any = this.platform.is('ios') ? 'iOS' : 'Android';

    this.device = {
      user: this.user,
      platform: checkPlatform,
      os: this.deviceService.platform,
      deviceModel: this.deviceService.model,
      pushToken,
    };

    return this.device;
  }

  public async createDevice(
    section: string | string[] = 'standard',
    urlParams: string[] = [],
    url: string = null,
  ) {
    const device = await this.getDeviceInfo();
    if (!device) {
      return false;
    }

    super.create(device, section, urlParams, url);
  }

  public async subscribeToTopic(topic: string): Promise<any> {
    return this.fcm.subscribeToTopic(topic);
  }

  public async unsubscribeFromTopic(topic: string): Promise<any> {
    return this.fcm.unsubscribeFromTopic(topic);
  }

  public isMobile(): boolean {
    return this.platform.is('cordova');
  }

  public async getVersion(): Promise<string> {
    if (!this.isMobile()) {
      return null;
    }

    return this.appVersion.getVersionNumber();
  }

  protected onNotification() {
    this.fcm
      .onNotification()
      .subscribe((notificationData: NotificationData) => {
        const basicObservable: BasicObservable = {
          hash: notificationData.type,
          payload: notificationData,
        };

        this.basicObservableService.create(
          basicObservable,
          'push-notifications',
        );
      });
  }
}
