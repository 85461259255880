import { Component, OnDestroy, OnInit } from '@angular/core';
import { PolicyType } from '@interfaces/policies/policies.interface';
import { TranslateService } from '@ngx-translate/core';
import { PoliciesService } from '@services/policies/policies.service';
import {
  NgcCookieConsentService,
  NgcInitializationErrorEvent,
  NgcInitializingEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cookies-banner',
  templateUrl: 'cookies-banner.template.html',
})
export class CookiesBannerComponent implements OnInit, OnDestroy {
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializingSubscription: Subscription;
  private initializedSubscription: Subscription;
  private initializationErrorSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  private tranlationKeys: string[] = [
    'header',
    'message',
    'dismiss',
    'allow',
    'deny',
    'link',
    'policy',
  ];

  public policyType = PolicyType;

  constructor(
    private ccService: NgcCookieConsentService,
    private translate: TranslateService,
    private policiesService: PoliciesService,
  ) {}

  ngOnInit(): void {
    this.initCookiesBannerSubscriptions();
    this.initTranslateLangChangeSubscription();
  }

  private initCookiesBannerSubscriptions() {
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        // if (event.status) {
        //   this.ccService.getConfig().enabled = false;
        // }
      },
    );

    this.initializedSubscription = this.ccService.initialized$.subscribe(() => {
      // the cookieconsent has been successfully initialized.
      // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
    });

    this.initializationErrorSubscription =
      this.ccService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          // the cookieconsent has failed to initialize...
          console.log(
            `initializationError: ${JSON.stringify(event.error?.message)}`,
          );
        },
      );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('statusChangeSubscription', event);
      },
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      (event) => {
        // you can use this.ccService.getConfig() to do stuff...
      },
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      },
    );
  }

  private initTranslateLangChangeSubscription() {
    this.translate.onLangChange.subscribe((lang) => {
      this.changeCookiesLanguage();
    });
  }

  public changeCookiesLanguage() {
    this.translate //
      .get(this.tranlationKeys.map((l) => `cookie.${l}`))
      .subscribe((data) => {
        this.setDataWithPoliciesLink(data);
      });
  }

  private async setDataWithPoliciesLink(data): Promise<void> {
    const currentLang = this.translate.currentLang;

    this.ccService.getConfig().content =
      this.ccService.getConfig().content || {};
    this.ccService.getConfig().content.href =
      await this.policiesService.getPolicy(PolicyType.COOKIES, currentLang);
    // Override default messages with the translated ones

    this.tranlationKeys.forEach((l) => {
      this.ccService.getConfig().content[l] = data[`cookie.${l}`];
    });

    this.ccService.destroy(); //remove previous cookie bar (with default messages)
    this.ccService.init(this.ccService.getConfig()); // update config with translated messages
  }

  ngOnDestroy(): void {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
