<div class="modal-body modal-login">
  <span class="modal-content__title" translate>public.modal-user.title</span>

  <form
    class="form mt-30"
    [formGroup]="signupForm"
    (keydown.enter)="doSignup()"
  >
    <app-loading [loadingStatus]="(signupLoading$ | async)"></app-loading>
    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!signupForm.controls.name.valid && signupForm.controls.name.dirty"
      >
        <ion-input
          formControlName="name"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.nameandlastname' | translate"
        ></ion-input>
      </ion-item>

      <app-error-input-form
        [showError]="!signupForm.controls.name.valid && signupForm.controls.name.dirty"
        [form]="signupForm"
        item="name"
      ></app-error-input-form>
    </div>

    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!signupForm.controls.email.valid && signupForm.controls.email.dirty"
      >
        <ion-input
          type="email"
          formControlName="email"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.email' | translate"
        ></ion-input>
      </ion-item>

      <app-error-input-form
        [showError]="!signupForm.controls.email.valid && signupForm.controls.email.dirty"
        [form]="signupForm"
        item="email"
      ></app-error-input-form>
    </div>

    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!signupForm.controls.password.valid && signupForm.controls.password.dirty"
      >
        <ion-input
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.password' | translate"
        ></ion-input>

        <ion-button
          (click)="showPassword = !showPassword"
          class="button button--circle button--no-bg"
        >
          <i
            class="fa-light"
            [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
        </ion-button>
      </ion-item>

      <app-error-input-form
        [showError]="!signupForm.controls.password.valid && signupForm.controls.password.dirty"
        [form]="signupForm"
        item="password"
      ></app-error-input-form>
    </div>

    <div class="form__group mt-15 pb-0">
      <ion-item
        class="form__item form__item--checkbox"
        [class.form__item--error]="!signupForm.controls.terms.valid && signupForm.controls.terms.dirty"
      >
        <ion-label
          >{{ 'public.modal-user.agree' | translate }}
          <a [href]="policyTermsUrl | async" target="_blank" translate
            >public.modal-user.terms</a
          ></ion-label
        >
        <ion-checkbox
          [class.form__checkbox--error]="!signupForm.controls.terms.valid && signupForm.controls.terms.dirty"
          formControlName="terms"
          class="form__checkbox"
          slot="start"
        >
        </ion-checkbox>
      </ion-item>
    </div>

    <div class="form__group">
      <ion-item
        class="form__item form__item--checkbox"
        [class.form__item--error]="!signupForm.controls.policies.valid && signupForm.controls.policies.dirty"
      >
        <ion-label
          >{{ 'public.modal-user.agree' | translate }}
          <a [href]="policyPrivacyUrl | async" target="_blank" translate
            >public.modal-user.policy</a
          ></ion-label
        >
        <ion-checkbox
          [class.form__checkbox--error]="!signupForm.controls.policies.valid && signupForm.controls.policies.dirty"
          formControlName="policies"
          class="form__checkbox"
          slot="start"
        >
        </ion-checkbox>
      </ion-item>
    </div>
  </form>

  <ion-grid>
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="auto">
        <ion-button
          [disabled]="this.signupForm.invalid || (signupLoading$ | async)"
          (click)="doSignup()"
          class="button button--normal button--login"
          translate
          >session.signup
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
