import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthModule } from '@services/auth/auth.module';
import { UserGuard } from './user.guard';
import { RoleGuard } from './role.guard';
import { PlatformGuard } from './platform.guard';
import { FamiliarGuard } from './familiar.guard';

@NgModule({
  imports: [CommonModule, AuthModule],
})
export class GuardModule {
  static forRoot(): ModuleWithProviders<GuardModule> {
    return {
      ngModule: GuardModule,
      providers: [UserGuard, RoleGuard, PlatformGuard, FamiliarGuard],
    };
  }
}
