import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { FCM, NotificationData } from '@awesome-cordova-plugins/fcm/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import {
  Config,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { DeviceService } from '@ionServices/device/device.service';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { ConfigsService } from '@services/config/config.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { PoliciesService } from '@services/policies/policies.service';
import { ScreenService } from '@services/screen/screen.service';
import { UserSections } from '@shared/components/toolbar/user-modal/components/interfaces/user-sections.interface';
import {
  filter,
  map,
  Observable,
  ReplaySubject,
  Subscription,
  takeUntil,
} from 'rxjs';
import {
  AdminAcademySections,
  AdminFamiliarSections,
} from './modules/admin/constants/admin.sections';
import {
  getElement,
  getPayload,
} from '@shared/utils/helpers/basic-observable.helper';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { CategoriesService } from '@services/categories/categories.service';
import { CryptoService } from '@services/crypto/crypto.service';
import { PaymentSections } from '@shared/components/payment-modal/constants/payment.sections';
import { FavoritesService } from '@services/favorites/favorites.service';
import { UserInterface, UserRolesEnum } from '@interfaces/user/user.interface';
import { ActivityChecker } from '@services/activity-checker/activity-checker.service';
import { sessionExpiredSection } from '@services/abstract/abstract.auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.template.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  protected subPushNotifications: Subscription;
  protected subErrors: Subscription;

  public isFamiliarUser: Observable<boolean> = this.authService
    .getObservable()
    .pipe(
      filter((user) => !!user),
      map((user: UserInterface) => !user.isAcademy),
    );

  public ignoreErrors: string[] = [
    ...Object.values(UserSections),
    ...Object.values(AdminAcademySections),
    ...Object.values(AdminFamiliarSections),
    ...Object.values(PaymentSections),
    'errors.medium.public-get-medium',
  ].filter((section) => section.startsWith('errors.'));

  public tabButtons = [
    {
      title: 'public.welcome.search',
      icon: 'fa-light fa-magnifying-glass',
      route: '/public/category',
      roles: [UserRolesEnum.FAMILIAR, UserRolesEnum.ACADEMY],
      visible: true,
    },
    {
      title: 'admin.menu.familiar.myreserves',
      icon: 'fa-light fa-calendar-days',
      route: '/admin/reserves',
      roles: [UserRolesEnum.FAMILIAR, UserRolesEnum.ACADEMY],
      visible: true,
    },
    {
      title: 'public.favorites.myfavorites',
      icon: 'fa-solid fa-heart',
      route: '/public/favorites',
      roles: [UserRolesEnum.FAMILIAR],
      visible: true,
    },
    {
      title: 'admin.menu.academy.profile',
      icon: 'fa-light fa-user',
      route: '/admin',
      roles: [UserRolesEnum.FAMILIAR, UserRolesEnum.ACADEMY],
      visible: true,
    },
  ];

  constructor(
    private favoritesService: FavoritesService,
    private cryptoService: CryptoService,
    private categoriesService: CategoriesService,
    private geoLocation: GeolocationService,
    private screenService: ScreenService,
    private configService: ConfigsService,
    protected readonly config: Config,
    protected readonly platform: Platform,
    protected readonly splashScreen: SplashScreen,
    protected readonly authService: AuthService,
    protected readonly statusBar: StatusBar,
    protected readonly deviceService: DeviceService,
    protected readonly deeplinks: Deeplinks,
    protected readonly basicObservableService: BasicObservableService,
    protected readonly storage: LocalStorageService,
    protected readonly errorsService: ErrorsService,
    protected readonly toasterIonic: IonicToasterService,
    protected readonly navCtrl: NavController,
    private translate: TranslateService,
    private fcm: FCM,
    public toastController: ToastController,
    protected readonly router: Router,
    protected policiesService: PoliciesService,
    protected activityChecker: ActivityChecker,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initializeApp();
    this.authService.loadTokens();
    this.listenTokenExpired();
    this.authService
      .getObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: UserInterface) => {
        const role = user?.role ?? UserRolesEnum.FAMILIAR;
        this.tabButtons.forEach((tab) => {
          tab.visible = tab.roles.includes(role);
        });
      });

    this.listenErrors();

    await this.platform.ready();

    if (this.deviceService.isMobile()) {
      this.initializeMobileApp();
    }

    this.onNotification();
  }

  async initializeApp() {
    //    this.config.set('backButtonIcon', 'icon-arrowleftpurple');
    this.initTranslate();
    this.listenToUserActivity();
  }

  async initializeMobileApp() {
    // this.statusBar.styleDefault();
    this.statusBar.overlaysWebView(this.platform.is('ios'));
    // this.statusBar.backgroundColorByHexString('#ffffff');
    this.splashScreen.hide();
    // this.deviceService.getDeviceInfo();

    this.deeplinks
      .routeWithNavController(this.navCtrl, {
        'no-match': AppComponent,
      })
      .subscribe(
        (match) => {
          // match.$route - the route we matched, which is the matched entry from the arguments to route()
          // match.$args - the args passed in the link
          // match.$link - the full link data
          console.log('Successfully matched route', match);
          this.router.navigate([match.$link.path]); // '/public/login/change-password/:resetHash
        },
        (nomatch) => {
          // nomatch.$link - the full link data
          console.error("Got a deeplink that didn't match", nomatch);
          this.router.navigate([nomatch.$link.path]);
        },
      );
  }

  private listenToUserActivity() {
    this.activityChecker.listenToUserActivity();
  }

  protected listenErrors() {
    this.subErrors = this.errorsService
      .getObservable('errors')
      .pipe(getElement())
      .subscribe((error: ErrorServiceInterface) => {
        this.onError(error);
      });
  }

  protected onError(error: ErrorServiceInterface) {
    if (this.ignoreErrors.includes(error.section)) {
      return;
    }

    this.errorsService.remove(error.id);
    let statusCode = error.payload.status;
    const payloadHash = error.payload.code ?? 'unknown';

    if (statusCode === 404) {
      this.navCtrl.navigateRoot('/error');
      return;
    }

    if (payloadHash === 'tokenexpired') {
      this.navCtrl.navigateRoot('/public');
      statusCode = 1000;
    }

    this.toasterIonic.toasterError(statusCode);
  }

  protected onNotification() {
    this.fcm
      .onNotification()
      .subscribe((notificationData: NotificationData) => {
        this.checkNotification(notificationData);
      });
  }

  checkNotification(notification: NotificationData) {
    if (notification.wasTapped) {
      this.closedApp(notification);
    } else {
      this.openedApp(notification);
    }
  }

  closedApp(notification: NotificationData) {
    this.redirectionNotification(notification.type, notification.hash);
  }

  openedApp(notification: NotificationData) {
    this.toastNotifications(notification, 'successToast');
  }

  redirectionNotification(typeNotification, hash = '') {
    switch (typeNotification) {
      default:
        this.navCtrl.navigateRoot('/admin');
        break;
    }
  }

  async toastNotifications(notification: NotificationData, styleCss: string) {
    const showMessage = this.platform.is('android')
      ? notification.body
      : notification.aps.alert.body;
    const ionToast = await this.toastController.create({
      message: showMessage,
      cssClass: styleCss,
      position: 'top',
      duration: 30000,
      buttons: [
        {
          side: 'end',
          text: 'VER',
          handler: () => {
            this.redirectionNotification(notification.type, notification.hash);
          },
        },
      ],
    });
    ionToast.present();
  }

  initTranslate() {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    const lang = this.translate.getBrowserLang();
    if (lang === 'es' || lang === 'en') {
      this.translate.use(this.translate.getBrowserLang());
    }
    this.storage.create({
      key: 'language',
      value: this.translate.store.defaultLang.toUpperCase(),
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.subPushNotifications.unsubscribe();
    this.subErrors.unsubscribe();
  }

  private listenTokenExpired() {
    this.basicObservableService
      .getObservable(sessionExpiredSection)
      .pipe(
        takeUntil(this.destroyed$),
        getPayload(),
        map((payload: any) => payload),
      )
      .subscribe((payload) => this.handleTokenExpired(payload.sessionExpired));
  }

  private handleTokenExpired(tokenExpired: boolean) {
    if (tokenExpired) {
      this.toasterIonic.toasterError(999, {
        999: 'errors.auth.inactive',
      });
    }
  }
}
