import { Injectable } from '@angular/core';
import {
  MediumQueryParams,
  MediumResponse,
} from '@interfaces/medium/medium-posts.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class MediumService extends AbstractService<
  MediumResponse,
  MediumQueryParams
> {
  protected errorsSection = 'errors.medium';
  protected endPoint = '/medium';
}
