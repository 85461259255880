import { Injectable } from '@angular/core';
import { UserInterface } from '@interfaces/user/user.interface';
import { UserQueryParamsInterface } from '@interfaces/user/user.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';
import { getElement } from '@shared/utils/helpers/basic-observable.helper';
import { lastValueFrom, map, take, tap } from 'rxjs';

@Injectable()
export class AcademiesService extends AbstractService<UserInterface, any> {
  protected errorsSection = 'errors.academies';
  protected endPoint = '/academies/';

  public async getAcademySync(academyId: string): Promise<UserInterface> {
    const section = 'get-academy-sync';
    const endPoint = `${this.endPoint}%s`;
    this.getOne('', section, [academyId], endPoint);
    return lastValueFrom(
      this.getObservable(section).pipe(
        getElement(),
        map((data: UserInterface) => data),
        tap(() => this.cleanSection(section)),
        take(1),
      ),
    );
  }

  public changePassword(
    academyId: string,
    updatePasswordData: UserQueryParamsInterface,
    section: string,
  ): void {
    const endPoint = `${this.endPoint}${academyId}/update-password`;
    this.update('', updatePasswordData, section, [], endPoint);
  }
}
