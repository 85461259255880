<div class="language-switcher flex flex-nowrap">
  <ng-container *ngFor="let language of languages; index as i">
    <ion-button
      fill="clear"
      class="language-switcher__language"
      [ngClass]="{
        'language-switcher__language--active': translate.currentLang == language
      }"
      (click)="changeLanguage(language)"
    >
      <span>{{ language }}</span>
    </ion-button>
    <span class="color-primary" *ngIf="i < languages.length - 1">|</span>
  </ng-container>
</div>
