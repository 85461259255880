import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { updateObservable } from '@shared/utils/helpers/basic-observable.helper';

@Component({
  selector: 'app-familiar-only-modal',
  templateUrl: 'logout-message-modal.template.html',
})
export class LogoutMessageModalComponent implements OnInit {
  @Input() openLoginWhenDismiss: boolean = true;
  @Input() messageKeys: string[];
  @Input() titleKey: string;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private bOBService: BasicObservableService,
  ) {}

  ngOnInit(): void {}

  public closeMenu(): void {
    this.modalCtrl.dismiss({ logOff: false }, 'close');
  }

  public logout(): void {
    this.authService.logout();
    this.modalCtrl.dismiss({ logOff: true }, 'close');
    if (this.openLoginWhenDismiss) {
      updateObservable(this.bOBService, 'show-modal', 'login');
    }
  }
}
