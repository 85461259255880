import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Token } from '@interfaces/token/token.interface';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AcademyOnboardingStatus } from '@interfaces/academy/onboarding-status.enum';
import { ActivityChecker } from '@services/activity-checker/activity-checker.service';

enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

interface ApiKeyRequest {
  path: string | RegExp;
  method: RequestMethods;
}

const apiKeyOnlyEndpoints: ApiKeyRequest[] = [
  {
    path: 'categories',
    method: RequestMethods.GET,
  },
  {
    path: 'policies',
    method: RequestMethods.GET,
  },
  {
    path: 'activities/?(?!.*/|favorites).*',
    method: RequestMethods.GET,
  },
  {
    path: 'activities/[^/]+/visits',
    method: RequestMethods.POST,
  },
  {
    path: 'faq-sections',
    method: RequestMethods.GET,
  },
  {
    path: 'activities-subthemes',
    method: RequestMethods.GET,
  },
  {
    path: 'medium',
    method: RequestMethods.GET,
  },
  {
    path: 'press-releases',
    method: RequestMethods.GET,
  },
];

const apiKeyOnlyRequests: ApiKeyRequest[] = apiKeyOnlyEndpoints.map(
  (endpoint) => ({
    ...endpoint,
    path: `${environment.api}/${endpoint.path}`,
  }),
);

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private tokens: Token;
  private apiKey: string;

  public auth$: Observable<Token> = this.authService
    .getObservable('tokens')
    .pipe(
      filter((tokens) => !!tokens),
      tap((tokens) => (this.tokens = tokens)),
    );

  constructor(
    private authService: AuthService,
    private activityChecker: ActivityChecker,
  ) {
    this.apiKey = environment.apiKey;
    this.tokens = null as Token;
    this.auth$.subscribe();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const authorizedRequest = request.headers.get('Authorization');
    const isApiRequest: boolean = request.url.startsWith(environment.api);

    const apiKeyOnlyRequest: boolean = !!apiKeyOnlyRequests.find(
      (apiRequest) =>
        !!request.url.match(apiRequest.path) &&
        apiRequest.method === request.method,
    );

    if (this.authService.currentUser) {
      this.activityChecker.setLastApiRequest();
    }

    if (authorizedRequest || !isApiRequest) {
      return next.handle(request);
    }

    const haveTokens = !!this.tokens?.accessToken;

    const authValue =
      !apiKeyOnlyRequest && haveTokens
        ? `Bearer ${this.tokens.accessToken}`
        : `ApiKey ${this.apiKey}`;

    const authHeader = {
      setHeaders: {
        Authorization: authValue,
      },
    };

    if (
      !!request.url.match('academies/[^/]+') &&
      request.method === RequestMethods.PUT &&
      this.authService.currentUser.onboardingStatus ===
        AcademyOnboardingStatus.STEP_5
    ) {
      Object.assign(request.body, {
        isUpdateFromProfile: true,
      });
    }

    if (haveTokens && !apiKeyOnlyRequest) {
      const unixDate = Math.floor(Date.now() / 1000);
      const tokenExpired = this.tokens.expiresIn <= unixDate;

      if (tokenExpired) {
        if (!this.authService.isRefreshing) {
          this.authService.refresh(false);
        }

        return this.authService.getObservable('refreshing').pipe(
          filter((_) => !_),
          switchMap(() => this.auth$),
          take(1),
          switchMap((tokens: Token) => {
            this.tokens = tokens;
            authHeader.setHeaders.Authorization = `Bearer ${this.tokens.accessToken}`;
            request = request.clone(authHeader);
            return next.handle(request);
          }),
        );
      }
    }

    request = request.clone(authHeader);
    return next.handle(request);
  }
}
