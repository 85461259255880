import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AgeSelectorComponent } from './age-selector.component';

@NgModule({
  imports: [IonicModule, ReactiveFormsModule, CommonModule],
  declarations: [AgeSelectorComponent],
  exports: [AgeSelectorComponent],
})
export class AgeSelectorModule {}
