import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordInterface } from '@interfaces/forgot-password/forgot-password.model.interface';
import { TranslateService } from '@ngx-translate/core';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { getElement } from '@shared/utils/helpers/basic-observable.helper';
import { Observable, ReplaySubject, takeUntil, tap } from 'rxjs';
import { UserSections } from '../interfaces/user-sections.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { ForgotPasswordService } from '@services/forgot-password/forgot-password.service';
import { ModalService } from '@services/modal/modal.service';
import { checkEmail } from '@shared/utils/customValidators/custom.validators';

@Component({
  selector: 'app-forgot-password-slide',
  templateUrl: 'forgot-password.template.html',
})
export class ForgotPasswordSlideComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public forgotForm: FormGroup;

  public forgotLoading$: Observable<boolean> =
    this.forgotPasswordService.getObservableUpdating(UserSections.FORGOT_SEND);

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private forgotPasswordService: ForgotPasswordService,
    private errorsService: ErrorsService,
    private toasterService: IonicToasterService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.prepareForm();

    this.forgotPasswordService
      .getObservable(UserSections.FORGOT_SEND)
      .pipe(
        takeUntil(this.destroyed$),
        getElement(),
        tap(() =>
          this.forgotPasswordService.cleanSection(UserSections.FORGOT_SEND),
        ),
      )
      .subscribe((user) => this.forgotSuccess());

    this.errorsService
      .getObservable(UserSections.FORGOT_SEND_ERRORS)
      .pipe(takeUntil(this.destroyed$), getElement())
      .subscribe((error: ErrorServiceInterface) => {
        this.errorsService.remove(error.id);
        const errorData: HttpErrorResponse = error.payload;

        if (errorData.status === 401) {
          this.forgotSuccess();
          return;
        }

        this.forgotError(errorData);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private prepareForm(): void {
    this.forgotForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, checkEmail()])],
    });
  }

  public doForgotPassword(): void {
    if (this.forgotForm.invalid) return;
    const forgotData: ForgotPasswordInterface = this.forgotForm.value;
    this.forgotPasswordService.create(forgotData, UserSections.FORGOT_SEND);
  }

  private forgotSuccess() {
    this.toasterService.toasterSuccess('public.modal-user.forgotsuccess');
    this.modalService.closeActiveModal();
  }

  private forgotError(error: HttpErrorResponse) {
    const statusCode = error.status;
    this.toasterService.toasterError(statusCode);
  }
}
