import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ActivityChecker } from './activity-checker.service';

@NgModule({
  imports: [CommonModule],
})
export class ActivityCheckerModule {
  static forRoot(): ModuleWithProviders<ActivityCheckerModule> {
    return {
      ngModule: ActivityCheckerModule,
      providers: [ActivityChecker],
    };
  }
}
