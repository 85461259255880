import { ModelInterface, NewModelInterface } from "@interfaces/global/model.interface";

export enum ThemeTypes {
  THEME = 'theme',
  SUBTHEME = 'subtheme',
  SUBTHEMECLASS = 'subthemeclass',
}

export interface CategoriesInterface extends NewModelInterface, ModelInterface {
  themes: ThemeInterface[];
}

export interface MultiThemeInterface extends ThemeInterface, SubThemeInterface, SubThemeClassesInterface {
  themeType: string;
  imageURL: string;
}

export interface ThemeInterface {
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  id: string;
  name: string;
  nameTranslation: string;
  imageUrl?: string;
  categories?: string[];
  subthemes?: SubThemeInterface[];
}

export interface SubThemeInterface {
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  id: string;
  name: string;
  nameTranslation: string;
  imageUrl?: string;
  categories?: string[];
  themeId?: string;
  subthemeClasses?: SubThemeClassesInterface[];
}

export interface SubThemeClassesInterface {
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    id: string;
    name: string;
    nameTranslation: string;
    categories?: string[];
    subthemeId?: string;
}