<ion-content class="modal-content modal-menu no-scroll">
  <div class="menu-header">
    <div class="toolbar__logo ion-hide-sm-up"></div>

    <div class="ion-hide-sm-up">
      <app-language-switcher></app-language-switcher>
    </div>

    <i
      (click)="closeMenu()"
      class="button button--close-big fa-thin fa-circle-xmark"
    >
    </i>
  </div>

  <div class="ion-text-left ion-text-sm-center">
    <ion-row *ngFor="let item of menuItems" class="links">
      <ion-col size="12">
        <span class="links__title" translate>{{ item.category }}</span>
      </ion-col>
      <ion-col size="12" *ngFor="let link of item.links">
        <a
          *ngIf="link.type === LinkType.ROUTE"
          class="links__link"
          [target]="link.blank ? '_blank' : '_self'"
          [routerLink]="[link.link]"
          (click)="onLinkClick($event, link.onClick)"
          translate
          >{{ link.title }}</a
        >
        <a
          *ngIf="link.type !== LinkType.ROUTE && link.type !== LinkType.BUTTON"
          class="links__link"
          [target]="link.blank ? '_blank' : '_self'"
          [href]="[link.link]"
          (click)="onLinkClick($event, link.onClick)"
          translate
          >{{ link.title }}</a
        >
        <button
          *ngIf="link.type === LinkType.BUTTON"
          class="links__link"
          (click)="onLinkClick($event, link.onClick)"
          translate
        >
          {{ link.title }}
        </button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-no-padding">
        <ion-button
          (click)="logout()"
          class="button button--outline button--big mt-5"
          [ngClass]="{'ion-hide': (isLogged | async) === false}"
          fill="outline"
        >
          {{ 'session.logout' | translate }}
          <i
            class="button__icon--big fa-regular fa-arrow-right-from-bracket"
          ></i>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
