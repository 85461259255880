export enum AudienceEnum {
  general = 'general',
  admin = 'admin',
  cron = 'cron',
}

export enum AcademyAudiences {
  GENERAL = 'ACADEMY_GENERAL',
  ADMIN = 'ACADEMY_ADMIN',
}

export enum UserAudiences {
  GENERAL = 'GENERAL',
  ADMIN = 'ADMIN',
}