<form [formGroup]="formAge" class="age-selector">
  <div *ngFor="let age of [].constructor(maxAge + 1); index as i">
    <input
      [formControlName]="'age' + i"
      [id]="'age' + i"
      type="checkbox"
      class="age-selector__checkbox"
    />

    <label [for]="'age' + i" class="age-selector__label">{{ i }}</label>
  </div>
</form>
