export enum AdminAcademySections {
  // GEERAL DATA
  ACADEMIES_UPDATE = 'academies-update',
  ACADEMIES_UPDATE_ERROR = 'errors.academies.academies-update',

  // OTHER DATA
  ACADEMIES_OTHER_UPDATE = 'academies-update-other',
  ACADEMIES_OTHER_UPDATE_ERROR = 'errors.academies.academies-update-other',

  // CHANGE PASSWORD
  ACADEMIES_CHANGE_PASSWORD = 'academies-change-password',
  ACADEMIES_CHANGE_PASSWORD_ERROR = 'errors.academies.academies-change-password',

  // RESPONSIBLES
  ACADEMIES_RESPONSIBLES_UPDATE = 'responsibles-update',
  ACADEMIES_RESPONSIBLES_UPDATE_ERROR = 'errors.academies.responsibles-update',

  // PROFESSORS
  ACADEMIES_PROFESSOR_LOAD = 'load-professors',
  ACADEMIES_PROFESSOR_LOAD_ERROR = 'errors.professors.load-professors',

  ACADEMIES_PROFESSOR_ADD = 'new-professor',
  ACADEMIES_PROFESSOR_ADD_ERROR = 'errors.professors.new-professor',

  ACADEMIES_PROFESSOR_UPDATE = 'update-professor',
  ACADEMIES_PROFESSOR_UPDATE_ERROR = 'errors.professors.update-professor',

  // CATEGORIES
  ACADEMIES_LIST_CATEGORIES = 'list-categories',
  ACADEMIES_LIST_CATEGORIES_ERROR = 'errors.categories.list-categories',

  // ACTIVITIES
  ACADEMIES_LIST_ACTIVITIES = 'list-activities',
  ACADEMIES_LIST_ACTIVITIES_ERROR = 'errors.activities.list-activities',

  ACADEMIES_CREATE_ACTIVITY = 'new-activity',
  ACADEMIES_CREATE_ACTIVITY_ERROR = 'errors.activities.new-activity',

  ACADEMIES_GET_ACTIVITY = 'get-activity',
  ACADEMIES_GET_ACTIVITY_ERROR = 'errors.activities.get-activity',

  ACADEMIES_UPDATE_ACTIVITY = 'update-activity',
  ACADEMIES_UPDATE_ACTIVITY_ERROR = 'errors.activities.update-activity',
  ACADEMIES_GET_SESSIONS = 'get-sessions',
  ACADEMIES_GET_SESSIONS_ERROR = 'errors.sessions.get-sessions',

  ACADEMIES_ADD_SESSIONS = 'add-sessions',
  ACADEMIES_ADD_SESSIONS_ERROR = 'errors.sessions.add-sessions',

  ACADEMIES_EDIT_SESSIONS = 'edit-sessions',
  ACADEMIES_EDIT_SESSIONS_ERROR = 'errors.sessions.edit-sessions',

  ACADEMIES_SESSIONS_ERROR = 'errors.sessions',

  ACADEMIES_GET_ACTIVITY_FILTER = 'filters.activity',
  ACADEMIES_GET_PROFESSOR_FILTER = 'filters.professor',
  ACADEMIES_GET_AGES_FILTER = 'filters.ages',

  // RESERVATIONS
  ACADEMIES_CANCEL_RESERVATION = 'cancel-reservation',
  ACADEMIES_CANCEL_RESERVATION_ERROR = 'errors.stripe.cancel-reservation',
  ACADEMIES_CONFIRM_RESERVATION = 'stripe.confirm-reservation',
  ACADEMIES_CANCEL_CONFIRM_RESERVATION_ERROR = 'errors.stripe',
  ACADEMIES_GET_RESERVATIONS = 'get-reservations',
  ACADEMIES_GET_RESERVATIONS_ERROR = 'errors.reservations.get-reservations',
  ACADEMIES_GET_RESERVATIONS_STATS = 'get-reservations-stats',
  ACADEMIES_GET_RESERVATIONS_STATS_ERROR = 'errors.reservations.get-reservations-stats',
  ACADEMIES_GET_RESERVATIONS_PARTICIPANTS = 'get-reservations-participants',
  ACADEMIES_GET_RESERVATIONS_PARTICIPANTS_ERROR = 'errors.reservations.get-reservations-participants',

  // VISITS
  ACADEMIES_GET_VISITS = 'get-visits',
  ACADEMIES_GET_VISITS_ERROR = 'errors.visits.get-visits',

  ACADEMIES_CONNECT_STRIPE = 'stripe-connect',
  ACADEMIES_CONNECT_STRIPE_ERROR = 'errors.stripe-connector.stripe-connect',

  // Onboarding Update
  ACADEMIES_UPDATE_ONBOARDING = 'academies-update-onboarding',
  ACADEMIES_UPDATE_ONBOARDING_ERROR = 'errors.academies.academies-update-onboarding',
}

export enum AdminFamiliarSections {
  // GENERAL DATA
  USER_UPDATE = 'user-update',
  USER_UPDATE_ERROR = 'errors.user.user-update',

  //UPDATE VALORATION
  USER_UPDATE_VALORATION = 'user-update-valoration',

  // CHANGE PASSWORD
  USER_CHANGE_PASSWORD = 'user-change-password',
  USER_CHANGE_PASSWORD_ERROR = 'errors.user.user-change-password',

  // RESERVATIONS
  USER_GET_RESERVATIONS = 'user-get-reservations',
  USER_GET_RESERVATIONS_ERROR = 'errors.reservations.user-get-reservations',
}
