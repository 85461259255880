import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { UserSections } from '../interfaces/user-sections.interface';
import { SlidesActions } from '../interfaces/slide-actions.enum';
import {
  getElement,
  updateObservable,
} from '@shared/utils/helpers/basic-observable.helper';
import { UserInterface } from '@interfaces/user/user.interface';
import {
  checkEmail,
  checkPasswordCustom,
} from '@shared/utils/customValidators/custom.validators';
import { AcademiesService } from '@services/academies/academies.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { Observable, ReplaySubject, filter, skip, takeUntil, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { ModalService } from '@services/modal/modal.service';
import { NavController } from '@ionic/angular';
import { PoliciesService } from '@services/policies/policies.service';
import { PolicyType } from '@interfaces/policies/policies.interface';
import { adventagesCards } from '../adventages/adventages-cards.mockup';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
import { AuthService } from '@services/auth/auth.service';
import { AcademyOnboardingStatus } from '@interfaces/academy/onboarding-status.enum';

SwiperCore.use([Pagination]);
@Component({
  selector: 'app-signup-academy-slide',
  templateUrl: 'signup-academy.template.html',
})
export class SignupAcademySlideComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() action = new EventEmitter<string>();

  public policyTermsUrl: Observable<string> =
    this.policiesService.getPolicyObservable(
      PolicyType.ACADEMY_TERMS_AND_CONDITIONS,
      this.translate.currentLang,
    );

  public policyPrivacyUrl: Observable<string> =
    this.policiesService.getPolicyObservable(
      PolicyType.PRIVACY,
      this.translate.currentLang,
    );

  signupType = SlidesActions;
  signupAcademyForm: FormGroup;
  showPassword: boolean;

  public adventagesCards = adventagesCards;

  public adventagesSwiperConfig: SwiperOptions = {
    pagination: {
      enabled: true,
      bulletClass: 'swiper__bullet',
      bulletActiveClass: 'swiper__bullet--active',
    },
    slidesPerView: 'auto',
    slideClass: 'modal-adventages__slide',
    wrapperClass: 'modal-adventages__swiper-wrapper',
  };

  public signupLoading$: Observable<boolean> =
    this.academyService.getObservableUpdating(UserSections.ACADEMY_NEW);

  constructor(
    private bOBService: BasicObservableService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private academyService: AcademiesService,
    private errorsService: ErrorsService,
    private toasterService: IonicToasterService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private policiesService: PoliciesService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.prepareForm();

    const userAlreadyLogged = !!this.authService.currentUser;

    this.authService
      .getObservable()
      .pipe(
        takeUntil(this.destroyed$),
        filter((user: UserInterface) => !!user && !!user.id),
        skip(userAlreadyLogged ? 1 : 0),
      )
      .subscribe((user: UserInterface) => {
        this.authSuccess(user);
      });

    this.academyService
      .getObservable(UserSections.ACADEMY_NEW)
      .pipe(
        takeUntil(this.destroyed$),
        getElement(),
        tap(() => this.academyService.cleanSection(UserSections.ACADEMY_NEW)),
      )
      .subscribe((user: UserInterface) => this.signupSuccess(user));

    this.errorsService
      .getObservable(UserSections.ACADEMY_ERRORS)
      .pipe(takeUntil(this.destroyed$), getElement())
      .subscribe((error: ErrorServiceInterface) => {
        const errorData: HttpErrorResponse = error.payload;
        this.signupError(errorData);
        this.errorsService.remove(error.id);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  prepareForm(): void {
    this.signupAcademyForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, checkEmail()])],
      password: [
        '',
        Validators.compose([Validators.required, checkPasswordCustom()]),
      ],
      terms: [
        null,
        Validators.compose([Validators.required, Validators.requiredTrue]),
      ],
      policies: [
        null,
        Validators.compose([Validators.required, Validators.requiredTrue]),
      ],
    });
  }

  signupSuccess(user: UserInterface): void {
    this.modalService.closeActiveModal();

    this.authService.login({
      email: user.email,
      password: this.signupAcademyForm.value.password,
    });
  }

  private authSuccess(user: UserInterface) {
    this.modalService.closeActiveModal();

    if (user.isAcademy) {
      this.redirectUserToOnboardingStage(user);
    } else if (!user?.childAges) {
      this.showOnBoardingModal();
    }
  }

  signupError(error: HttpErrorResponse): void {
    const statusCode = error.status;

    const errorCodes = {
      400: 'errors.signup.alreadyexists',
    };

    this.toasterService.toasterError(statusCode, errorCodes);

    this.signupAcademyForm.reset();
  }

  private showOnBoardingModal() {
    updateObservable(this.bOBService, 'show-modal', 'onboarding');
  }

  public goAdventages(): void {
    if (this.signupAcademyForm.invalid) return;

    const signupFormData = this.signupAcademyForm.value;

    delete signupFormData.terms;
    delete signupFormData.policies;

    const termsAndConditions: Date = new Date();
    const privacyPolicies: Date = termsAndConditions;

    const signupData: UserInterface = {
      ...signupFormData,
      termsAndConditions,
      privacyPolicies,
    };

    this.academyService.create(signupData, UserSections.ACADEMY_NEW);
  }

  private redirectUserToOnboardingStage(user: UserInterface) {
    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_1)
      this.navCtrl.navigateRoot('/admin/academy/general');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_2)
      this.navCtrl.navigateRoot('/admin/academy/other');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_3)
      this.navCtrl.navigateRoot('/admin/academy/responsible');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_4)
      this.navCtrl.navigateRoot('/admin/academy/stripe');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_5)
      this.navCtrl.navigateRoot('/admin/academy/management/activities');

    if (!user.onboardingStatus)
      this.navCtrl.navigateRoot('/admin/academy/general');
  }
}
