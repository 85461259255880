import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import { StripeConnection } from '@interfaces/stripe/stripe.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class StripeConnector extends AbstractService<
  StripeConnection,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.stripe-connector';
  protected endPoint = '/stripe/connection/%s';
}
