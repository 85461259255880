import { Injectable } from '@angular/core';
import { ForgotPasswordInterface } from '@interfaces/forgot-password/forgot-password.model.interface';
import { ForgotPasswordQueryParamsInterface } from '@interfaces/forgot-password/forgot-password.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ForgotPasswordService extends AbstractService<
  ForgotPasswordInterface,
  ForgotPasswordQueryParamsInterface
> {
  protected errorsSection = 'errors.forgot-password';
  protected endPoint = '/forgot-passwords/';
}
