import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: 'loading.template.html',
})
export class LoadingComponent {
  @Input() loadingStatus: boolean;
  @Input() noBg: boolean;

  constructor() {}
}
