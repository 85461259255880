import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import {
  PoliciesInterface,
  PoliciesParsedInterface,
  PolicyInterface,
  PolicyType,
} from '@interfaces/policies/policies.interface';
import { AbstractService } from '@services/abstract/abstract.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { ErrorsService } from '@services/errors/errors.service';
import {
  createObservable,
  getAllData,
  getPayload,
  resolvePath,
} from '@shared/utils/helpers/basic-observable.helper';
import { Observable, filter, lastValueFrom, map, take, tap } from 'rxjs';

@Injectable()
export class PoliciesService extends AbstractService<
  PoliciesInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.policies';
  protected endPoint = '/policies/';

  private policiesHash: 'policies';
  private policies$: Observable<any>;
  private policiesLoaded: boolean;

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected bOBService: BasicObservableService,
  ) {
    super(http, errorsService);

    this.policies$ = this.bOBService
      .getObservable(this.policiesHash)
      .pipe(getPayload());

    this.bOBService
      .getObservable(this.policiesHash)
      .pipe(take(1))
      .subscribe((policies: any) => {
        if (!policies.length) {
          this.loadPolicies();
        }
      });
  }

  private loadPolicies(): void {
    const policiesSection = 'get-policies';

    this.getObservable(policiesSection)
      .pipe(
        getAllData(),
        map((policies: any) => (policies as PoliciesInterface).policies),
        tap(() => this.cleanSection(policiesSection)),
        take(1),
      )
      .subscribe((policies: PolicyInterface[]) => {
        this.policiesLoaded = true;
        const processedPolicies = this.processPolicies(policies);
        createObservable(this.bOBService, this.policiesHash, processedPolicies);
      });

    this.getAll({}, policiesSection);
  }

  private processPolicies(
    policies: PolicyInterface[],
  ): PoliciesParsedInterface {
    return policies.reduce((prev, next) => {
      const type = next.type;
      const language = next.language;
      const url = next.url;

      return {
        ...prev,
        [type]: {
          ...prev[type],
          [language]: url,
        },
      };
    }, {});
  }

  public getPolicies(): Observable<PoliciesParsedInterface> {
    return this.policies$;
  }

  public getPolicyObservable(
    policy: PolicyType,
    lang: string,
  ): Observable<string> {
    if (!lang) return;
    return this.policies$.pipe(
      filter((_) => this.policiesLoaded),
      map(
        (policies) =>
          resolvePath(policies, `${policy}.${lang.toUpperCase()}`) ?? '#',
      ),
      take(1),
    );
  }

  async getPolicy(policy: PolicyType, lang: string): Promise<string> {
    return lastValueFrom(
      this.policies$.pipe(
        filter((_) => this.policiesLoaded),
        map(
          (policies) =>
            resolvePath(policies, `${policy}.${lang.toUpperCase()}`) ?? '#',
        ),
        take(1),
      ),
    );
  }
}
