import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SwiperModule } from 'swiper/angular';
import { CategoryListComponent } from './category-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
  imports: [
    LoadingModule,
    TranslateModule,
    SwiperModule,
    AngularSvgIconModule.forRoot(),
    IonicModule,
    CommonModule,
  ],
  declarations: [CategoryListComponent],
  exports: [CategoryListComponent],
})
export class CategoryListModule {}
