import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserInterface } from '@interfaces/user/user.interface';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { UsersService } from '@services/users/users.service';
import { getElement } from '@shared/utils/helpers/basic-observable.helper';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: 'onboarding-modal.template.html',
})
export class OnBoardingModalComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public agesSelected: string[] = [];
  public userLoading: Observable<boolean> =
    this.userService.getObservableUpdating('child-ages');

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private userService: UsersService,
    private authService: AuthService,
    private errorsService: ErrorsService,
    private toasterService: IonicToasterService,
  ) {}

  ngOnInit(): void {
    this.userService
      .getObservable('child-ages')
      .pipe(takeUntil(this.destroyed$), getElement())
      .subscribe((user: UserInterface) => this.updateAgesSuccess(user));

    this.errorsService
      .getObservable('errors.user.child-ages')
      .pipe(takeUntil(this.destroyed$), getElement())
      .subscribe((error: ErrorServiceInterface) => {
        const errorData: HttpErrorResponse = error.payload;
        this.updateAgesError(errorData);
        this.errorsService.remove(error.id);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public closeMenu(): void {
    this.modalCtrl.dismiss(null, 'close');
  }

  public agesChanged(ages: string[]): void {
    this.agesSelected = ages;
  }

  public updateChildAges(): void {
    if (!this.agesSelected.length) return;

    const userId = this.authService.currentUser.id;

    const userData: UserInterface = {
      childAges: this.agesSelected,
    };

    this.userService.update(userId, userData, 'child-ages');
  }

  private updateAgesSuccess(user: UserInterface) {
    this.authService.updateUserData(user);
    this.closeMenu();
  }

  private updateAgesError(error: HttpErrorResponse) {
    const errorMessage = 'errors.message.unknown';
    const message = this.translate.instant(errorMessage);
    this.toasterService.presentToastWithOptions(
      `Error: ${message}`,
      'toast toast--error',
    );
  }
}
