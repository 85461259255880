import { ModuleWithProviders, NgModule } from '@angular/core';
import { BasicObservableModule } from '../basic-observable/basic-observable.module';
import { CryptoService } from './crypto.service';

@NgModule({
  imports: [BasicObservableModule],
})
export class CryptoServiceModule {
  static forRoot(): ModuleWithProviders<CryptoServiceModule> {
    return {
      ngModule: CryptoServiceModule,
      providers: [CryptoService],
    };
  }
}
