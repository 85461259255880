import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import { ProfessorInterface } from '@interfaces/professors/professors.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ProfessorsService extends AbstractService<
  ProfessorInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.professors';
  protected endPoint = '/professors/';
}
