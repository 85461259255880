import { ModuleWithProviders, NgModule } from '@angular/core';
import { BasicObservableModule } from '../basic-observable/basic-observable.module';
import { ScreenService } from './screen.service';

@NgModule({
  imports: [BasicObservableModule],
})
export class ScreenServiceModule {
  static forRoot(): ModuleWithProviders<ScreenServiceModule> {
    return {
      ngModule: ScreenServiceModule,
      providers: [ScreenService],
    };
  }
}
