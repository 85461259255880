const categoryTypes = [
  {
    title: 'Planes del día',
    name: 'plansoftheday',
  },
  {
    title: 'Campamentos',
    name: 'camps',
  },
  {
    title: 'Cumpleaños',
    name: 'birthdays',
  },
  {
    title: 'Extraescolares',
    name: 'extracurricular',
  },
];

const categories = [
  {
    title: 'sports',
    active: true,
  },
  {
    title: 'dance',
  },
  {
    title: 'nature',
  },
  {
    title: 'languages',
  },
  {
    title: 'art',
  },
  {
    title: 'music',
  },
  {
    title: 'specialneeds',
  },
  {
    title: 'academics',
  },
  {
    title: 'performingarts',
  },
  {
    title: 'others',
  },
];

const subCategories = [
  {
    title: 'gymnastics',
  },
  {
    title: 'football',
  },
  {
    title: 'handball',
    active: true,
  },
  {
    title: 'basketball',
  },
  {
    title: 'tennis',
  },
  {
    title: 'swimming',
  },
  {
    title: 'athletics',
  },
  {
    title: 'snow',
  },
  {
    title: 'inlineskating',
  },
  {
    title: 'marcials',
  },
  {
    title: 'cycling',
  },
  {
    title: 'wellbeing',
  },
  {
    title: 'adventure',
  },
  {
    title: 'chess',
  },
  {
    title: 'horses',
  },
];

export { categoryTypes, categories, subCategories };
