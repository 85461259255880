import { environment } from '@environments/environment';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'bottom',
  palette: {
    popup: {
      background: '#1F1F1F',
      link: '#fff',
      text: '#fff',
    },
    button: {
      background: '#000099',
    },
  },
  container: document.getElementById('cookies-banner') as HTMLElement,
  theme: 'classic',
  type: 'opt-out',
  revokable: false,
  revokeBtn: '<span class="ion-hide"></span>', // To hide revokable button because 'revocable: false' doesn´t work
  showLink: true,
};
