import { Injectable } from '@angular/core';
import { ScheduleService, WeekDaysNumberLower } from './schedule.service';
import { ActivityInterface } from '@interfaces/activities/activities.interface';
import { SessionInterface } from '@interfaces/sessions/sessions.interface';

@Injectable()
export class ExtracurricularScheduleFormatter {
  run(activity: ActivityInterface) {
    const availableSessions: SessionInterface[] = activity.sessions.filter(
      (session) => session.availablePlaces > 0,
    );
    const availableSessionsWeekDays = availableSessions.map((session) => {
      return session.schedules.map((schedule) => {
        return schedule.weekDay;
      });
    });
    const uniqueSessionsWeekDays = [
      ...new Set(availableSessionsWeekDays.flat()),
    ];
    return uniqueSessionsWeekDays.sort(
      (a, b) => Number(WeekDaysNumberLower[a]) - Number(WeekDaysNumberLower[b]),
    );
  }
}
