import { CryptoInterface } from '../shared/interfaces/crypto/crypto.interface';
import { environmentBase } from './environment.base';

export const environment = {
  ...environmentBase,
  api: 'https://ubniompjdlgfkmfrnvndal.becubs.com/v1',
  apiKey: '1a8MQYR9R8uCv6eH2v6UNa',
  cookieDomain: 'becubs.com',
  stripe: {
    clientId: 'ca_NfB7qYiByzRU4FPQrP9RjRvtQMPgOclK',
    redirectionUrl: 'https://www.becubs.com/admin/academy/stripe',
    baseUrl: 'https://connect.stripe.com/oauth/authorize',
    stripeBaseParams: 'response_type=code&scope=read_write',
    // PROD SECRET KEY
    clientSecret:
      'pk_live_51Mgm5vIk4N56Ce6fF0JVhQtllgotZR6e9l7YswMCmvQfFcs6x1JGG9jES6rv4WN8pxHKUw0mURgsSk3n9OSA4Z9f00BLwtQFnn',
  },
  inactivityMaxMinutes: 45,
  crypto: {
    algorithm: 'aes-256-ctr',
    iv: 'BCRr4myY8HB?&eMB',
    key: 'C$C6bE54gqB5gFCMrzX&F&kjMbik49qG',
    cipher_input_encoding: 'utf-8',
    cipher_output_encoding: 'hex',
  } as CryptoInterface,
};
