import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Favorite,
  FavoritesResponse,
} from '@interfaces/favorites/favorites.interface';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import { UserInterface } from '@interfaces/user/user.interface';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { AbstractService } from '@services/abstract/abstract.service';
import { AuthService } from '@services/auth/auth.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { ErrorsService } from '@services/errors/errors.service';
import {
  createObservable,
  updateObservable,
  getAllData,
  getPayload,
  getElement,
} from '@shared/utils/helpers/basic-observable.helper';

import { Observable, filter, map, tap } from 'rxjs';

enum FavoritesSections {
  GET = 'favorites-get',
  LOADED = 'favorites-loaded',
  FAVORITES = 'favorites-items',
  CREATE = 'favorites-create',
}

@Injectable()
export class FavoritesService extends AbstractService<
  any,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.favorites';
  protected endPoint = '/activities/favorites/';

  public favorites: Observable<Favorite[]> = this.bOBService
    .getObservable(FavoritesSections.FAVORITES)
    .pipe(
      getPayload(),
      filter((_) => Array.isArray(_)),
      map((_) => _ as Favorite[]),
    );

  public loaded: Observable<boolean> = this.bOBService
    .getObservable(FavoritesSections.LOADED)
    .pipe(
      getPayload(),
      map((_) => Boolean(_)),
    );

  public syncFavorites: Favorite[] = [];
  public syncLoaded: boolean;

  private deleting: boolean; // En el abstract falta poder añadir section al delete ;)
  private deletingId: string;
  private userId: string;

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected bOBService: BasicObservableService,
    protected authService: AuthService,
    protected toasterService: IonicToasterService,
  ) {
    super(http, errorsService);
    createObservable(this.bOBService, FavoritesSections.FAVORITES, []);
    createObservable(this.bOBService, FavoritesSections.LOADED, false);

    this.initListeners();

    this.authService.getObservable().subscribe((user: UserInterface) => {
      if (!!user?.id && !user?.isAcademy && this.userId !== user?.id) {
        this.userId = user.id;
        this.loadFavorites();
      } else {
        this.syncFavorites = [];
        updateObservable(
          this.bOBService,
          FavoritesSections.FAVORITES,
          this.syncFavorites,
        );
      }
    });
  }

  public loadFavorites(): void {
    updateObservable(this.bOBService, FavoritesSections.LOADED, false);
    this.syncLoaded = false;
    this.getAll({}, FavoritesSections.GET);
  }

  public toggleFavorite(activityId: string) {
    const favExists: Favorite = this.syncFavorites.find(
      (fav) => fav.activityId === activityId,
    );

    if (!!favExists) {
      this.deleting = true;
      this.deletingId = favExists.id;
      this.deleteFavorite(this.deletingId);
    } else {
      this.createFavorite(activityId, FavoritesSections.CREATE);
    }
  }

  private createFavorite(activityId: string, section: string): void {
    const endPoint = '/activities/%s/favorites';
    this.create({}, section, [activityId], endPoint);
  }

  private deleteFavorite(favoriteId: string): void {
    this.delete(favoriteId);
  }

  private initListeners(): void {
    // CREATE FAVORITE
    this.getObservable(FavoritesSections.GET)
      .pipe(
        getAllData(),
        map((favResp: FavoritesResponse) => favResp.favorites),
        tap(() => this.cleanSection(FavoritesSections.GET)),
      )
      .subscribe((favorites: Favorite[]) => {
        this.syncFavorites = favorites;
        this.syncLoaded = true;

        updateObservable(
          this.bOBService,
          FavoritesSections.FAVORITES,
          this.syncFavorites,
        );

        updateObservable(this.bOBService, FavoritesSections.LOADED, true);
      });

    this.getObservable(FavoritesSections.CREATE)
      .pipe(
        getElement(),
        map((_) => _ as Favorite),
      )
      .subscribe(() => {
        this.loadFavorites();
        this.toasterService.toasterSuccess('public.favorites.added');
      });

    // DELETE FAVORITE
    this.getObservableUpdating()
      .pipe(filter((updating) => !updating && this.deleting))
      .subscribe(() => {
        this.deleting = false;
        const deleteIdx = this.syncFavorites.findIndex(
          (fav) => fav.id === this.deletingId,
        );
        if (deleteIdx > -1) {
          this.loadFavorites();
          this.toasterService.toasterSuccess('public.favorites.removed');
        }
      });
  }

  public isFavorite(activityId): Observable<boolean> {
    return this.favorites.pipe(
      map(
        (favorites) =>
          !!favorites.find((favorite) => favorite.activityId === activityId),
      ),
    );
  }
}
