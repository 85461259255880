import { Injectable } from '@angular/core';
import { CategoriesApi } from '@interfaces/categories/categories.enum';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import { StripeInterface } from '@interfaces/stripe/stripe.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class StripeService extends AbstractService<
  StripeInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.stripe';
  protected endPoint = '/stripe/%s/';

  public createPayment(
    category: CategoriesApi,
    paymentData: StripeInterface,
    section: string,
  ): void {
    const apiCategory = {
      [CategoriesApi.BIRTHDAYS]: 'birthday',
      [CategoriesApi.CAMPS]: 'camp',
      [CategoriesApi.PLANSOFTHEDAY]: 'day-plans',
      [CategoriesApi.EXTRACURRICULAR]: 'extracurricular',
    }[category];

    this.create(paymentData, section, [apiCategory], this.endPoint);
  }

  public cancelBirthdayFuturePayment(
    data: StripeInterface,
    section: string,
  ): void {
    const endPoint = `/stripe/birthday/cancel`;
    this.update('', data, section, [], endPoint);
  }

  public cancelDayPlansPayments(data: StripeInterface, section: string): void {
    const endPoint = `/stripe/day-plans/cancel`;
    this.update('', data, section, [], endPoint);
  }

  public cancelCampPayment(data: StripeInterface, section: string): void {
    const endPoint = `/stripe/camp/cancel`;
    this.update('', data, section, [], endPoint);
  }

  public confirmCampPayment(data: StripeInterface, section: string): void {
    const endPoint = `/stripe/camp/confirm`;
    this.update('', data, section, [], endPoint);
  }

  public cancelExtracurricularSubscription(
    data: StripeInterface,
    section: string,
  ): void {
    const endPoint = `/stripe/extracurricular/cancel`;
    this.update('', data, section, [], endPoint);
  }
}
