import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { PressReleaseService } from './press-release.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class PressReleaseServiceModule {
  static forRoot(): ModuleWithProviders<PressReleaseServiceModule> {
    return {
      ngModule: PressReleaseServiceModule,
      providers: [PressReleaseService],
    };
  }
}
