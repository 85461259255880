export enum Categories {
  PLANSOFTHEDAY = 'plansoftheday',
  CAMPS = 'camps',
  BIRTHDAYS = 'birthdays',
  EXTRACURRICULAR = 'extracurricular'
}

export enum CategoriesApi {
  PLANSOFTHEDAY = 'PlansOfTheDay',
  CAMPS = 'Camps',
  BIRTHDAYS = 'Birthdays',
  EXTRACURRICULAR = 'Extracurricular'
}

export const categories: Categories[] = Object.values(Categories);