import { Injectable } from '@angular/core';
import { Location } from '@interfaces/location/local.model.interface';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { take } from 'rxjs';
import {
  createObservable,
  updateObservable,
} from '@shared/utils/helpers/basic-observable.helper';
import { GeoLocationSections } from './geolocation.sections';

@Injectable()
export class GeolocationService {
  public userLocation: Location;
  public error: GeolocationPositionError;
  public loaded: boolean;

  constructor(
    private googlemapsService: GoogleMapsService,
    private bOBService: BasicObservableService,
  ) {
    if (navigator.geolocation) {
      createObservable(
        this.bOBService,
        GeoLocationSections.USER_LOCATION,
        null,
      );
      navigator.geolocation.getCurrentPosition(
        this.savePosition.bind(this),
        this.errPosition.bind(this),
      );
    }
  }

  private savePosition(pos: GeolocationPosition): void {
    const { latitude, longitude } = pos.coords;

    this.googlemapsService
      .getProvinceFromCoordinates(latitude, longitude)
      .pipe(take(1))
      .subscribe((response) => {
        const province = this.googlemapsService.getProvinceFromAdress(
          response.results[0],
        );
        this.userLocation = {
          lat: latitude.toString(),
          lng: longitude.toString(),
          province,
        };
        updateObservable(
          this.bOBService,
          GeoLocationSections.USER_LOCATION,
          this.userLocation,
        );
      });
  }

  private errPosition(error: GeolocationPositionError): void {
    this.error = error;
    updateObservable(this.bOBService, GeoLocationSections.USER_LOCATION, error);
  }
}
