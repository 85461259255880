import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [TranslateModule, IonicModule, CommonModule],
  declarations: [LanguageSwitcherComponent],
  exports: [LanguageSwitcherComponent],
})
export class LanguageSwitcherModule {}
