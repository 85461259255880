<ion-footer>
  <div class="footer container">
    <div class="footer__container container__center">
      <div class="footer__start">
        <svg-icon
          class="footer__logo mb-40 ion-hide-lg-down"
          src="/assets/img/logo_becubs.svg"
        ></svg-icon>
        <h3 class="footer__title mb-20" translate>public.footer.title_short</h3>
        <p class="footer__text mb-30 ion-hide-lg-down" translate
          >public.footer.description</p
        >
        <ion-button
          (click)="knowMore()"
          class="footer__button button button--big ion-hide-lg-down"
          translate
          >public.footer.learnmore
        </ion-button>
        <ion-button
          (click)="knowMore()"
          class="footer__button-compact button button--big ion-hide-lg-up mt-10"
          translate
          >public.footer.learnmore
        </ion-button>
      </div>
      <div class="footer__end ion-hide-lg-down">
        <div class="mb-40">
          <a
            href="https://www.facebook.com/profile.php?id=100083330852746"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="footer__icon fa-brands fa-facebook-f mr-50"></i>
          </a>
          <a
            href="https://www.instagram.com/becubs.spain/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="footer__icon fa-brands fa-instagram"></i>
          </a>
        </div>

        <div class="footer__lists mb-30">
          <div class="footer__list">
            <span class="footer__list-title">Becubs</span>
            <a
              [routerLink]="'/public/about-us'"
              class="footer__list-link"
              translate
              >public.modal-menu.knowus</a
            >
            <a
              [routerLink]="['/public/contact']"
              class="footer__list-link"
              translate
              >public.modal-menu.contact</a
            >
            <a
              href="https://medium.com/@admin_49077"
              target="_blank"
              class="footer__list-link"
              >Blog</a
            >
          </div>
          <div class="footer__list">
            <span class="footer__list-title" translate
              >public.modal-menu.help</span
            >
            <a [routerLink]="'public/faqs'" class="footer__list-link">FAQs</a>
            <a
              [href]="legalTermsPolicy"
              target="_blank"
              class="footer__list-link"
              translate
              >public.modal-menu.legalwarning</a
            >
            <a
              [href]="userTermsPolicy"
              target="_blank"
              class="footer__list-link"
              translate
              >public.modal-menu.terms</a
            >
            <a
              [href]="academyTermsPolicy"
              target="_blank"
              class="footer__list-link"
              translate
              >public.modal-menu.termsAcademy</a
            >
            <a
              [href]="privacyPolicy"
              target="_blank"
              class="footer__list-link"
              translate
              >public.modal-menu.privacypolicy</a
            >
            <a
              [href]="cookiesPolicy"
              target="_blank"
              class="footer__list-link"
              translate
              >public.modal-menu.cookies</a
            >
          </div>
        </div>

        <span class="footer__copy">
          © Becubs 2023. {{ 'public.modal-menu.rights' | translate }}
        </span>
      </div>
    </div>
  </div>
</ion-footer>
