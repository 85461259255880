import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigsService } from '@services/config/config.service';
import { Observable, take } from 'rxjs';

@Injectable()
export class GoogleMapsService {
  private apiKey: string;
  private googleApiUrl: string =
    'https://maps.googleapis.com/maps/api/geocode/json';

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigsService,
  ) {
    this.configService
      .getConfigParam('googleApiKey')
      .pipe(take(1))
      .subscribe((apiKey) => (this.apiKey = apiKey));
  }

  getProvinceFromCoordinates(lat: number, lon: number): Observable<any> {
    return this.httpClient.get(this.googleApiUrl, {
      params: {
        key: this.apiKey,
        latlng: lat + ',' + lon,
      },
    });
  }

  getProvinceFromAdress(address: any): string {
    const data = address?.address_components?.find(
      (a) => a?.types[0] === 'administrative_area_level_2',
    );
    return data?.long_name || null;
  }
}
