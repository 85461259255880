import { ProfessorInterface } from '@interfaces/professors/professors.interface';
import { ModelInterface } from '../global/model.interface';
import { AcademyOnboardingStatus } from '@interfaces/academy/onboarding-status.enum';

export enum CompanyType {
	FREELANCER = 'freelancer',
	PRIVATE_LIMITED = 'private limited',
	COOPERATIVE = 'cooperative',
}

export interface Responsible {
	name: string;
	nif: string;
	email: string;
	phone?: string;
}

export interface UserInterface extends ModelInterface {
	activated?: boolean;
	audience?: string[];
	childAges?: string[];
	companyType?: CompanyType;
	description?: string;
	email?: string;
	imageURL?: string;
	isAcademy?: boolean;
	location?: UserLocation;
	name?: string;
	nif?: string;
	oldPassword?: string;
	password?: string;
	phone?: string;
	privacyPolicies?: Date;
	reservationMail?: string;
	responsibles?: Responsible[]
	role?: UserRolesEnum;
	stripeKey?: string;
	termsAndConditions?: Date;
	website?: string;
	professors?: ProfessorInterface[];
	needGeneralData?: boolean;
	needOtherData?: boolean;
	needResponsibles?: boolean;
	color?: string;
	onboardingStatus?: AcademyOnboardingStatus
}

export interface UserLocation {
	address?: string;
	province?: string;
	lat?: string;
	lng?: string;
	street?: string;
	streetNumber?: string;
	locality?: string;
	postalCode?: string;
}

export enum UserRolesEnum {
	ACADEMY = 'academy',
	FAMILIAR = 'familiar'
}
