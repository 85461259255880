import { Injectable } from '@angular/core';
import { ScheduleService } from './schedule.service';
import { ActivityInterface } from '@interfaces/activities/activities.interface';
import { SessionInterface } from '@interfaces/sessions/sessions.interface';

@Injectable()
export class DayPlanScheduleFormatter {
  constructor(private scheduleService: ScheduleService) {}

  run(activity: ActivityInterface) {
    const availableSessions: SessionInterface[] = activity.sessions.filter(
      (session) => session.availablePlaces > 0,
    );

    const availableSessionsMonthDays = availableSessions.map((session) => {
      return session.schedules.map((schedule) => {
        if (schedule.availablePlaces > 0) {
          return schedule.monthDay;
        }
      });
    });

    const uniqueSessionsMonthDays = [
      ...new Set(availableSessionsMonthDays.flat()),
    ];

    let minDay;
    let maxDay;
    uniqueSessionsMonthDays.forEach((monthDay) => {
      if (!minDay) minDay = monthDay;
      if (!maxDay) maxDay = monthDay;
      if (minDay && minDay > monthDay) minDay = monthDay;
      if (maxDay && maxDay < monthDay) maxDay = monthDay;
    });
    const startDay = this.scheduleService.parseDate(minDay);
    const endDay = this.scheduleService.parseDate(maxDay);
    return {
      startDay,
      endDay,
    };
  }
}
