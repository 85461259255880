import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthCredentials } from '@interfaces/token/token.interface';
import { UserInterface } from '@interfaces/user/user.interface';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { UsersService } from '@services/users/users.service';
import {
  checkEmail,
  checkPasswordCustom,
} from '@shared/utils/customValidators/custom.validators';
import { getElement } from '@shared/utils/helpers/basic-observable.helper';
import { ReplaySubject, takeUntil, Observable, tap } from 'rxjs';
import { UserSections } from '../interfaces/user-sections.interface';
import { PoliciesService } from '@services/policies/policies.service';
import { PolicyType } from '@interfaces/policies/policies.interface';
@Component({
  selector: 'app-signup-slide',
  templateUrl: 'signup.template.html',
})
export class SignupSlideComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public policyTermsUrl: Observable<string> =
    this.policiesService.getPolicyObservable(
      PolicyType.USER_TERMS_AND_CONDITIONS,
      this.translate.currentLang,
    );

  public policyPrivacyUrl: Observable<string> =
    this.policiesService.getPolicyObservable(
      PolicyType.PRIVACY,
      this.translate.currentLang,
    );

  signupForm: FormGroup;
  showPassword: boolean;

  public signupLoading$: Observable<boolean> =
    this.userService.getObservableUpdating(UserSections.USER_NEW);

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private userService: UsersService,
    private errorsService: ErrorsService,
    private toasterService: IonicToasterService,
    private authService: AuthService,
    private policiesService: PoliciesService,
  ) {}

  ngOnInit(): void {
    this.prepareForm();

    this.userService
      .getObservable(UserSections.USER_NEW)
      .pipe(
        takeUntil(this.destroyed$),
        getElement(),
        tap(() => this.userService.cleanSection(UserSections.USER_NEW)),
      )
      .subscribe((user: UserInterface) => this.signupSuccess(user));

    this.errorsService
      .getObservable(UserSections.USER_ERRORS)
      .pipe(takeUntil(this.destroyed$), getElement())
      .subscribe((error: ErrorServiceInterface) => {
        const errorData: HttpErrorResponse = error.payload;
        this.signupError(errorData);
        this.errorsService.remove(error.id);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  prepareForm(): void {
    this.signupForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, checkEmail()])],
      password: [
        '',
        Validators.compose([Validators.required, checkPasswordCustom()]),
      ],
      terms: [
        null,
        Validators.compose([Validators.required, Validators.requiredTrue]),
      ],
      policies: [
        null,
        Validators.compose([Validators.required, Validators.requiredTrue]),
      ],
    });
  }

  doSignup(): void {
    if (this.signupForm.invalid) return;
    const signupFormData = this.signupForm.value;

    delete signupFormData.terms;
    delete signupFormData.policies;

    const termsAndConditions: Date = new Date();
    const privacyPolicies: Date = termsAndConditions;

    const signupData: UserInterface = {
      ...signupFormData,
      termsAndConditions,
      privacyPolicies,
    };

    this.userService.create(signupData, UserSections.USER_NEW);
  }

  signupSuccess(user: UserInterface) {
    const loginData: AuthCredentials = {
      email: this.signupForm.controls.email.value,
      password: this.signupForm.controls.password.value,
    };

    this.authService.login(loginData);
  }

  signupError(error: HttpErrorResponse) {
    const statusCode = error.status;

    const errorCodes = {
      400: 'errors.signup.alreadyexists',
    };

    this.toasterService.toasterError(statusCode, errorCodes);
    this.signupForm.reset();
  }
}
