import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-age-selector',
  templateUrl: 'age-selector.template.html',
})
export class AgeSelectorComponent implements OnInit, OnDestroy {
  @Output() selectedAgesChange = new EventEmitter<string[]>();
  @Input() selectAges: string[] = [];
  @Input() maxAge: number = 18;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public formAge: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.prepareForm();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  prepareForm() {
    let ageGroup = {};

    for (let i = 0; i <= this.maxAge; i++) {
      ageGroup = {
        ...ageGroup,
        [`age${i}`]: this.selectAges.includes(i.toString()),
      };
    }

    this.formAge = this.fb.group(ageGroup);

    this.formAge.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        map((ages) =>
          Object.entries(ages)
            .filter((age) => age[1])
            .map((age) => age[0].replace('age', '')),
        ),
      )
      .subscribe((ages) => {
        this.agesChanged(ages);
      });
  }

  agesChanged(ages: string[]): void {
    this.selectedAgesChange.emit(ages);
  }
}
