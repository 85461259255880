import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SlidesActions } from '../interfaces/slide-actions.enum';

@Component({
  selector: 'app-pre-signup-slide',
  templateUrl: 'pre-signup.template.html',
})
export class PreSignupComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter<SlidesActions>();

  signupType = SlidesActions;

  constructor(private translate: TranslateService, private fb: FormBuilder) {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  goSignup(signupType: SlidesActions) {
    this.action.emit(signupType);
  }
}
