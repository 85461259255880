import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { getPlatform } from '@interfaces/platform/platform.enum';
import { UserInterface, UserRolesEnum } from '@interfaces/user/user.interface';
import { NavController } from '@ionic/angular';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { AbstractAuthGuard } from '@services/abstract/abstract.auth.guard';
import { AuthService } from '@services/auth/auth.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { updateObservable } from '@shared/utils/helpers/basic-observable.helper';

@Injectable()
export class FamiliarGuard extends AbstractAuthGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected navCtrl: NavController,
    protected bOBService: BasicObservableService,
    protected toasterService: IonicToasterService,
  ) {
    super(authService, router);
  }

  protected check(user: UserInterface, state: RouterStateSnapshot): boolean {
    const isLogged: boolean = this.authService.isLogged;

    if (!isLogged) {
      updateObservable(this.bOBService, 'show-modal', 'login');
      return false;
    } else if (user.role === UserRolesEnum.ACADEMY) {
      this.navCtrl.navigateRoot('/public');
      this.toasterService.toasterError(900, {
        900: 'public.favorites.routeerror',
      });
      return false;
    }

    return true;
  }

  protected onError(error): boolean {
    return false;
  }
}
