import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Categories } from '@interfaces/categories/categories.enum';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-categories-modal',
  templateUrl: 'categories-modal.template.html',
})
export class CategoriesModalComponent implements OnInit, OnDestroy {
  public categories = [
    {
      title: Categories.PLANSOFTHEDAY,
      icon: 'wand-magic-sparkles',
    },
    {
      title: Categories.CAMPS,
      icon: 'house-night',
    },
    {
      title: Categories.BIRTHDAYS,
      icon: 'cake-candles',
    },
    {
      title: Categories.EXTRACURRICULAR,
      icon: 'person-running',
    },
  ];

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  goActivityType(category: string): void {
    this.modalCtrl.dismiss(category);
  }

  closeMenu() {
    this.modalCtrl.dismiss(null, 'close');
  }
}
