import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FooterComponent } from './footer.component';
import { PoliciesServiceModule } from '@services/policies/policies.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    TranslateModule,
    PoliciesServiceModule,
    AngularSvgIconModule.forRoot(),
    CommonModule,
    IonicModule,
    RouterModule,
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
