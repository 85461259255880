<p
  *ngIf="(showError || currentItem.dirty) && !!currentItem.errors"
  class="text-danger form__error ion-text-left"
>
  <i class="form__error-icon fa-light fa-circle-exclamation"></i>

  <span *ngIf="currentItem.hasError('samename')" translate
    >errors.forms.samename</span
  >

  <span *ngIf="currentItem.hasError('required')" translate
    >errors.forms.required</span
  >

  <span *ngIf="currentItem.hasError('checkEmail')" translate
    >errors.forms.email</span
  >

  <span *ngIf="currentItem?.hasError('checkPasswordCustom')" translate
    >errors.forms.password</span
  >

  <span *ngIf="currentItem.hasError('checkSamePasswords')" translate
    >errors.forms.passwordmatch</span
  >

  <span *ngIf="currentItem.hasError('checkNIF')" translate
    >errors.forms.nif</span
  >

  <span *ngIf="currentItem.hasError('timeFormat')"
    >The format must be HH:MM.</span
  >
  <span *ngIf="currentItem.hasError('dateFormat')"
    >The format must be MM/DD/YYYY.</span
  >
  <span *ngIf="currentItem.hasError('yearFormat')"
    >The format must be YYYY.</span
  >
  <span *ngIf="currentItem.hasError('minLengthCustom')"
    >The field can be min 6 characters long.</span
  >
  <span *ngIf="currentItem.hasError('maxLength')"
    >The field can be max 15 characters long.</span
  >
  <span *ngIf="currentItem.hasError('checkCreditCard')"
    >The credit card is not valid.</span
  >
</p>
