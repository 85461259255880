import { WeekDay } from '@angular/common';
import { Injectable } from '@angular/core';
import { CategoriesApi } from '@interfaces/categories/categories.enum';
import { DateObject, DateParsed } from '@interfaces/dates/dates.interface';
import {
  ScheduleInterface,
  SessionInterface,
} from '@interfaces/sessions/sessions.interface';

export enum DateType {
  WEEK = 'week',
  MONTH = 'month',
}

export enum DateMode {
  DAYS = 'days',
  RANGE = 'range',
}

enum WeekDaysNumber {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export enum WeekDaysNumberLower {
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  sunday = 7,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

@Injectable()
export class ScheduleService {
  public DateType = DateType;
  public DateMode = DateMode;
  public activityCategories = CategoriesApi;

  private onlyUnique(value: string, index: number, array: Array<any>) {
    return array.indexOf(value) === index;
  }

  public formatSchedules(schedules: ScheduleInterface[]): any[] {
    const dateType: DateType = !!schedules[0]?.weekDay
      ? DateType.WEEK
      : DateType.MONTH;

    if (dateType === DateType.WEEK) {
      const days = schedules
        .map((schedule) => schedule.weekDay)
        .filter(this.onlyUnique);

      const daysSorted = days.sort(
        (a, b) =>
          Number(WeekDaysNumberLower[a]) - Number(WeekDaysNumberLower[b]),
      );
      const range = {
        dateType,
        days: daysSorted,
      };

      return [range];
    } else {
      const monthRanges = schedules
        .map((schedule) => schedule.monthDay)
        .filter(this.onlyUnique);

      const dateMode: DateMode = monthRanges[0].includes('/')
        ? DateMode.RANGE
        : DateMode.DAYS;

      if (dateMode === DateMode.RANGE) {
        const ranges = monthRanges.map((monthRange) => {
          const startEnd = monthRange.split('/');
          const start: DateParsed = this.parseDate(startEnd[0]);
          const end: DateParsed = this.parseDate(startEnd[1]);
          const days = [start.dayName, end.dayName];
          return {
            dateMode,
            dateType,
            days,
            start,
            end,
          };
        });

        return ranges;
      } else {
        monthRanges.sort((a, b) => {
          const newDateA = new Date(a);
          const newDateB = new Date(b);
          return +newDateA - +newDateB;
        });

        const ranges = {
          days: monthRanges.map((monthRange) => this.parseDate(monthRange)),
          dateType,
          dateMode,
        };

        return [ranges];
      }
    }
  }

  public buildActivityDaysArray(range): DateObject {
    const datesObject: DateObject = {
      activityDateType: '',
      activityDateMode: '',
      activityDays: {},
    };

    /*     
    const map = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7,
    }; 
    */

    if (range.dateType === DateType.WEEK) {
      datesObject.activityDateType = DateType.WEEK;
      datesObject.activityDateMode = DateMode.DAYS;

      range.days = range.days.sort((a, b) => {
        //return map[a] - map[b];
        return (
          Number(WeekDaysNumberLower[a.toLowerCase()]) -
          Number(WeekDaysNumberLower[b.toLowerCase()])
        );
      });

      const dateReturn = range.days.map((weekday) => {
        return 'days.short_days.' + weekday.toLowerCase();
      });
      datesObject.activityDays = dateReturn;
    } else if (
      range.dateType === DateType.MONTH &&
      range.dateMode === DateMode.RANGE
    ) {
      datesObject.activityDateType = DateType.MONTH;
      datesObject.activityDateMode = DateMode.RANGE;

      datesObject.activityDays = [
        {
          start: {
            day: range.start.day,
            month: 'days.short_months.' + range.start.monthName,
            monthlong: 'days.' + range.start.monthName,
          },
          end: {
            day: range.end.day,
            month: 'days.short_months.' + range.end.monthName,
            monthlong: 'days.' + range.end.monthName,
          },
        },
      ];
    } else if (
      range.dateType === DateType.MONTH &&
      range.dateMode === DateMode.DAYS
    ) {
      datesObject.activityDateType = DateType.MONTH;
      datesObject.activityDateMode = DateMode.DAYS;
      const dateReturn = range.days.map((day) => {
        return {
          day: day.day,
          month: 'days.short_months.' + day.monthName,
          monthlong: 'days.' + day.monthName,
        };
      });
      datesObject.activityDays = dateReturn;
    }

    return datesObject;
  }

  public parseDate(date): DateParsed {
    date = new Date(date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const monthName = date
      .toLocaleString('en', { month: 'long' })
      .toLowerCase();

    const dayName = date
      .toLocaleString('en', { weekday: 'long' })
      .toLowerCase();

    return {
      day,
      month,
      year,
      monthName,
      dayName,
    };
  }

  public buildActivityDaysArrayWithSchedule(
    range,
    sessionName,
    activityCategory,
    sessionId,
  ): any {
    const datesObject: DateObject = {
      activityDateType: '',
      activityDateMode: '',
      activityDays: [],
    };

    if (activityCategory === this.activityCategories.EXTRACURRICULAR) {
      const rangeSorted = range.days.sort((a, b) => {
        return Number(WeekDaysNumber[a]) + Number(WeekDaysNumber[b]);
      });

      const dateReturn = rangeSorted.map((weekday) => {
        const weekDays = weekday.weekDay.split(',');
        const weekDaysT = weekDays.map((weekdayStr) => {
          return weekdayStr.toLowerCase();
        });
        weekDaysT.join(',');
        return { weekDaysT, start: weekday.startTime, end: weekday.endTime };
      });
      datesObject.activityDays = dateReturn;
    }
    if (activityCategory === this.activityCategories.CAMPS) {
      datesObject.activityDays = [
        {
          start: {
            day: range.dateStart.day,
            month: range.dateStart.month,
          },
          end: {
            day: range.dateEnd.day,
            month: range.dateEnd.month,
          },
          schedules: range.schedules,
        },
      ];
    }

    if (activityCategory === this.activityCategories.PLANSOFTHEDAY) {
      datesObject.activityDays = range;
    }

    if (activityCategory === this.activityCategories.BIRTHDAYS) {
      /*       
      const map = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 0,
      };  
      */
      range.forEach((session) => {
        session.weekDayNumber = WeekDay[session.weekDay];
        session.sessionName = sessionName;
      });
      datesObject.activityDays = range;
    }

    return { datesObject, sessionName, sessionId };
  }

  public formatSchedulesForCheckout(
    schedules: ScheduleInterface[],
    activityCategory: string,
  ): any[] {
    if (activityCategory === this.activityCategories.EXTRACURRICULAR) {
      const days = schedules.reduce((p, n) => {
        const index = p.findIndex(
          (schedule) =>
            schedule.startTime === n.startTime ||
            schedule.endTime === n.endTime,
        );

        if (index === -1) {
          p.push(n);
        } else {
          const weekDays = p[index].weekDay.split(',');
          if (!weekDays.includes(n.weekDay)) {
            weekDays.push(n.weekDay);
            const sortedWeekdays = weekDays.sort(
              (a, b) =>
                Number(WeekDaysNumberLower[a]) - Number(WeekDaysNumberLower[b]),
            );
            p[index].weekDay = sortedWeekdays.join(',');
          }
        }
        return p;
      }, []);
      const daysSorted = days.sort(
        (a, b) =>
          Number(WeekDaysNumberLower[a.weekDay]) -
          Number(WeekDaysNumberLower[b.weekDay]),
      );
      const formatedSchedule = {
        days: daysSorted,
      };
      return [formatedSchedule];
    }

    if (activityCategory === this.activityCategories.CAMPS) {
      const obj: any = {
        monthDay: '',
        schedules: [],
      };

      const monthRanges = schedules.reduce((p, n) => {
        const index = p.findIndex(
          (schedule) =>
            schedule.monthDay === n.monthDay &&
            (schedule.startTime !== n.startTime ||
              schedule.endTime !== n.endTime),
        );

        if (index === -1) {
          obj.monthDay = n.monthDay;
        }
        obj.schedules.push({ startTime: n.startTime, endTime: n.endTime });
        return p;
      }, []);

      const startEnd = obj.monthDay.split('/');
      const start: DateParsed = this.parseDate(startEnd[0]);
      const end: DateParsed = this.parseDate(startEnd[1]);

      return [
        {
          dateStart: {
            day: start.day,
            month: start.monthName,
          },
          dateEnd: {
            day: end.day,
            month: end.monthName,
          },
          schedules: obj.schedules,
        },
      ];
    }

    if (activityCategory === this.activityCategories.PLANSOFTHEDAY) {
      const monthRanges = schedules.reduce((p, n) => {
        const obj = {
          monthDay: '',
          schedules: [],
        };

        const index = p.findIndex(
          (schedule) =>
            schedule.monthDay === n.monthDay &&
            (schedule.startTime !== n.startTime ||
              schedule.endTime !== n.endTime),
        );

        if (index === -1) {
          obj.monthDay = n.monthDay;
          obj.schedules.push({
            startTime: n.startTime,
            endTime: n.endTime,
            id: n.id,
            sessionId: n.sessionId,
            availablePlaces: n.availablePlaces,
            excluded: n.excluded,
          });
          p.push(obj);
        } else {
          p[index].schedules.push({
            startTime: n.startTime,
            endTime: n.endTime,
            id: n.id,
            sessionId: n.sessionId,
            availablePlaces: n.availablePlaces,
            excluded: n.excluded,
          });
        }

        return p;
      }, []);

      return [monthRanges];
    }

    if (activityCategory === this.activityCategories.BIRTHDAYS) {
      const days = schedules.reduce((p, n) => {
        const obj = {
          weekDay: '',
          schedules: [],
        };
        const index = p.findIndex((schedule) => schedule.weekDay === n.weekDay);

        if (index === -1) {
          obj.weekDay = n.weekDay;
          obj.schedules.push({
            startTime: n.startTime,
            endTime: n.endTime,
            id: n.id,
            excluded: n.excluded,
            sessionId: n.sessionId,
            availablePlaces: n.availablePlaces,
            maximumKids: n.maximumKids,
            minimumKids: n.minimumKids,
            monthDay: n.monthDay,
          });
          p.push(obj);
        } else {
          p[index].schedules.push({
            startTime: n.startTime,
            endTime: n.endTime,
            id: n.id,
            excluded: n.excluded,
            sessionId: n.sessionId,
            availablePlaces: n.availablePlaces,
            maximumKids: n.maximumKids,
            minimumKids: n.minimumKids,
            monthDay: n.monthDay,
          });
        }

        return p;
      }, []);

      return [days];
    }
  }

  public unifySessionSchedules(sessions: any[], activityCategory) {
    const schedules = [];

    sessions.forEach((session) => {
      schedules.push(...session.session);
    });

    const schedulesUnified = schedules.reduce((p, n) => {
      let index;
      if (activityCategory === this.activityCategories.PLANSOFTHEDAY) {
        index = p.findIndex((session) => session.monthDay === n.monthDay);
      } else {
        index = p.findIndex((session) => session.weekDay === n.weekDay);
      }

      if (index === -1) {
        p.push(n);
      } else {
        p[index].schedules.push(...n.schedules);
      }
      return p;
    }, []);

    schedulesUnified.forEach((session) => {
      session.schedules.sort((a, b) => {
        /*         
        if (a.startTime < b.startTime) {
          return -1;
        }
        if (a.startTime > b.startTime) {
          return 1;
        }

        return 0; 
        */

        return a.startTime < b.startTime
          ? -1
          : a.startTime > b.startTime
          ? 1
          : 0;
      });
    });

    return schedulesUnified;
  }

  public getDaysBetweenDates(sDate: Date, eDate: Date, day: string): Date[] {
    const dates = [];
    sDate = new Date(sDate);
    eDate = new Date(eDate);
    let currentDate = sDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= eDate) {
      const dayName = currentDate
        .toLocaleDateString('en', { weekday: 'long' })
        .toLowerCase();
      if (!!day && day === dayName) {
        dates.push(currentDate);
      }
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  public getWeekDay(date: string): string {
    return new Date(date).toLocaleDateString('en', { weekday: 'long' });
  }

  public sortExtraCurricularsSessions(schedules) {
    return schedules.sort(
      (a, b) =>
        Number(
          WeekDaysNumberLower[a.datesObject.activityDays[0].weekDaysT[0]],
        ) -
        Number(WeekDaysNumberLower[b.datesObject.activityDays[0].weekDaysT[0]]),
    );
  }

  public sortWeeklySessionsSchedules(schedules) {
    return schedules.sort(
      (a, b) =>
        Number(WeekDaysNumberLower[a.weekDay]) -
        Number(WeekDaysNumberLower[b.weekDay]),
    );
  }

  public sortWeeklySessions(sessions) {
    return sessions.sort(
      (a, b) =>
        Number(WeekDaysNumberLower[a.schedules[0].weekDay]) -
        Number(WeekDaysNumberLower[b.schedules[0].weekDay]),
    );
  }

  public sortDayPlansSessions(sessions) {
    return sessions.sort((a, b) => {
      return a.schedules[0].monthDay > b.schedules[0].monthDay
        ? 1
        : a.schedules[0].monthDay < b.schedules[0].monthDay
        ? -1
        : 0;
    });
  }

  public sortCampsSessions(sessions: SessionInterface[]) {
    return sessions.sort((session1, session2) => {
      const session1Start = session1.schedules[0].monthDay.split('/')[0];
      const session2Start = session2.schedules[0].monthDay.split('/')[0];

      return session1Start > session2Start
        ? 1
        : session1Start < session2Start
        ? -1
        : 0;
    });
  }
}
