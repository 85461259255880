export const adventagesCards = [
  {
    title: 'public.modal-user.advancedsearch',
    description: 'public.modal-user.advancedsearchtext',
    icon: '/assets/img/icons/loupe.svg',
  },
  {
    title: 'public.modal-user.bookingsystem',
    description: 'public.modal-user.bookingsystemtext',
    icon: '/assets/img/icons/booking.svg',
  },
  {
    title: 'public.modal-user.managementtool',
    description: 'public.modal-user.managementtooltext',
    icon: '/assets/img/icons/resource.svg',
  },
  {
    title: 'public.modal-user.marketingtool',
    description: 'public.modal-user.marketingtooltext',
    icon: '/assets/img/icons/socialmarketing.svg',
  },
];
