import { ModuleWithProviders, NgModule } from '@angular/core';
import { BasicObservableModule } from '../basic-observable/basic-observable.module';
import { ConfigsService } from './config.service';

@NgModule({
  imports: [BasicObservableModule],
})
export class ConfigsServiceModule {
  static forRoot(): ModuleWithProviders<ConfigsServiceModule> {
    return {
      ngModule: ConfigsServiceModule,
      providers: [ConfigsService],
    };
  }
}
