<ion-header>
  <ion-toolbar class="toolbar ion-no-padding">
    <div class="container">
      <div class="container__center toolbar__container">
        <!-- Logo -->
        <ion-buttons>
          <a
            [routerLink]="['/public']"
            class="toolbar__logo"
            [class.toolbar__logo--academy]="!!user?.isAcademy"
          ></a>
        </ion-buttons>

        <!-- Categorias -->
        <ion-buttons
          *ngIf="showCategories"
          class="ion-justify-content-center toolbar__categories-container"
        >
          <div class="toolbar__categories">
            <ng-container *ngFor="let category of categoryTypes">
              <ion-button
                [routerLink]="['public', 'category', category.name]"
                fill="outline"
                class="button--toolbar toolbar__categories-button button--outline {{ 'button--' + category.name }}"
                [ngClass]="{'button--active': (categoryActive | lowercase) === category.name}"
              >
                {{ 'public.categories.' + category.name | translate }}
              </ion-button>
            </ng-container>
          </div>
        </ion-buttons>

        <ion-buttons>
          <ion-grid>
            <ion-row class="ion-align-items-center ion-nowrap">
              <!-- Categorias Modal -->
              <ion-col
                class="ion-no-padding"
                *ngIf="showCategories"
                class="toolbar__categories-container--mobile"
              >
                <ion-button
                  class="button--toolbar toolbar__categories-button toolbar__categories-switch button--outline button--active"
                  [ngClass]="categoryActive ? 'button--' + (categoryActive | lowercase) : ''"
                  fill="outline"
                  (click)="openCategoriesModal()"
                >
                  {{ categoryActive ? ('public.categories.' + (categoryActive |
                  lowercase) | translate) : ('admin.messages.categories' |
                  translate) }}
                  <i class="button__icon fa-regular fa-angle-down"></i>
                </ion-button>
              </ion-col>

              <!-- Username-->
              <ion-col
                *ngIf="isLogged"
                class="ion-hide-lg-down ion-no-padding pr-20"
              >
                <span class="toolbar__username">{{ user.name }}</span>
              </ion-col>

              <!-- Lenguajes -->
              <ion-col class="ion-hide-lg-down ion-no-padding pr-35">
                <app-language-switcher></app-language-switcher>
              </ion-col>

              <!-- Menu Modal -->
              <ion-col class="ion-no-padding toolbar__menu">
                <ion-button
                  (click)="openMenuModal()"
                  class="toolbar__menu-button-icon"
                >
                  <i class="fa-regular fa-bars"></i>
                </ion-button>
              </ion-col>

              <!-- Favs Modal -->
              <ion-col
                class="ion-no-padding toolbar__menu"
                *ngIf="!!user && user?.isAcademy === false"
              >
                <ion-button
                  [routerLink]="['/public/favorites']"
                  class="toolbar__menu-button-icon"
                >
                  <i class="fa-solid fa-heart"></i>
                </ion-button>
              </ion-col>

              <!-- Login Modal -->
              <ion-col class="ion-hide-md-down ion-no-padding">
                <ion-button
                  [routerLink]="isLogged ? null : ['/admin']"
                  [tp]="userPopover"
                  tpVariation="popoverMenu"
                  class="toolbar__menu-button-icon"
                >
                  <i *ngIf="!user?.imageURL" class="fa-solid fa-circle-user">
                  </i>

                  <ion-avatar *ngIf="user?.imageURL">
                    <img alt="avatar" [src]="user.imageURL" />
                  </ion-avatar>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-buttons>
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ng-template #userPopover let-hide>
  <div class="user-menu" *ngIf="isLogged">
    <div [routerLink]="[redirectUrl]" (click)="hide()" class="user-menu__item">
      <i class="fa-light fa-user"></i>{{ 'admin.header.profile' | translate }}
    </div>
    <div
      (click)="logout(); hide()"
      class="user-menu__item user-menu__item--logout"
    >
      <i class="fa-regular fa-arrow-right-from-bracket"></i>{{ 'session.logout'
      | translate }}
    </div>
  </div>
</ng-template>
