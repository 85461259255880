import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import {
  AuthCredentials,
  AuthResponseSuccess,
  JWTToken,
  Token,
  Token as TokenInterface,
} from '@interfaces/token/token.interface';
import { environment } from '@environments/environment';
import { AcademyAudiences } from '@interfaces/global/audience.enum.interface';
import { ExtendedRecordset } from '@interfaces/global/extendedRecordset.interface';
import { UserInterface, UserRolesEnum } from '@interfaces/user/user.interface';
import { AcademiesService } from '@services/academies/academies.service';
import { ErrorsService } from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { UsersService } from '@services/users/users.service';
import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';

interface StorageData {
  storageTokens: TokenInterface;
  storageUser: UserInterface;
}

export const sessionExpiredSection = 'session-expired';

@Injectable()
export abstract class AbstractAuthService {
  public currentUser: UserInterface;
  public currentToken: string;
  public currentRefreshToken: string;
  public isRefreshing: boolean;

  protected dataStore: {
    tokens: TokenInterface;
    user: UserInterface;
  };
  protected observables: {
    refreshing: BehaviorSubject<boolean>;
    storeLoaded: BehaviorSubject<boolean>;
    tokens: BehaviorSubject<TokenInterface>;
    user: BehaviorSubject<UserInterface>;
  };
  protected behaviorSubjects: {
    refreshing: Observable<boolean>;
    storeLoaded: Observable<boolean>;
    tokens: Observable<TokenInterface>;
    user: Observable<UserInterface>;
  };

  protected baseUrl: string;
  protected baseUrlRefreshToken: string;
  protected errorsSection: string;
  protected apiKey = environment.apiKey;

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected storage: LocalStorageService,
    protected session: SessionStorageService,
    protected userService: UsersService,
    protected academiesService: AcademiesService,
    protected basicObservableService: BasicObservableService,
  ) {
    this.errorsSection = 'errors.auth';
    this.baseUrl = `${environment.api}/auth/login`;
    this.baseUrlRefreshToken = `${environment.api}/auth/refresh-token`;

    this.dataStore = {
      tokens: {} as TokenInterface,
      user: {} as UserInterface,
    };

    this.observables = {
      tokens: new BehaviorSubject(null) as BehaviorSubject<TokenInterface>,
      user: new BehaviorSubject(null) as BehaviorSubject<UserInterface>,
      storeLoaded: new BehaviorSubject(false) as BehaviorSubject<boolean>,
      refreshing: new BehaviorSubject(false) as BehaviorSubject<boolean>,
    };

    this.behaviorSubjects = {
      tokens: this.observables.tokens.asObservable(),
      user: this.observables.user.asObservable(),
      storeLoaded: this.observables.storeLoaded.asObservable(),
      refreshing: this.observables.refreshing.asObservable(),
    };
  }

  getObservable(section = 'user'): Observable<any> {
    return this.behaviorSubjects[section];
  }

  protected updateObservable(
    observable: BehaviorSubject<any>,
    data: any,
  ): void {
    if (typeof data == 'object') {
      observable.next(Object.assign({}, data));
      return;
    }

    observable.next(data);
  }

  get isLogged(): boolean {
    return !!this.currentUser && !!this.currentUser.id;
  }

  get isLoggedObservable(): Observable<boolean> {
    return this.getObservable().pipe(
      map((user: UserInterface) => !!user && !!user.id),
    );
  }

  public login(credentials: AuthCredentials): void {
    // CLeaning storage before login to ensure no previous user data is wrongfully stored
    this.cleanDataStore();
    const headers = new HttpHeaders().set(
      'Authorization',
      `ApiKey ${this.apiKey}`,
    );

    this.http
      .post(this.baseUrl, credentials, { observe: 'response', headers })
      .subscribe({
        next: (response: HttpResponse<ExtendedRecordset<any>>) =>
          this.loginSuccess(response.body.data, response.headers),
        error: (error: HttpErrorResponse) => this.loginError(error),
      });
  }

  protected parseJWT(token: string): JWTToken {
    return JSON.parse(atob(token.split('.')[1]));
  }

  protected processTokens(
    data: AuthResponseSuccess,
    tokenDecoded: JWTToken,
  ): TokenInterface {
    const reduceTime = 0; //0;
    const { refreshToken, accessToken } = data;
    const expiresIn = tokenDecoded.exp - reduceTime;

    const tokens: TokenInterface = {
      accessToken,
      refreshToken,
      expiresIn,
    };

    return tokens;
  }

  protected async loginSuccess(
    data: AuthResponseSuccess,
    headers: HttpHeaders = {} as HttpHeaders,
  ): Promise<void> {
    const tokenDecoded: JWTToken = this.parseJWT(data.accessToken);

    const tokens: TokenInterface = this.processTokens(data, tokenDecoded);

    this.updateTokensStore(tokens);

    const audience: string[] = tokenDecoded.aud;
    const id: string = tokenDecoded.sub;

    this.refreshUser(id, audience);
  }

  public updateUserData(user: UserInterface): void {
    const updatedUser: UserInterface = {
      ...this.currentUser,
      ...user,
    };

    if (updatedUser.isAcademy) {
      Object.assign(updatedUser, this.checkAcademyData(user));
    }

    this.updateUserStore(updatedUser);
  }

  protected async refreshUser(id: string, audience: string[]) {
    const isAcademy =
      audience.includes(AcademyAudiences.ADMIN) ||
      audience.includes(AcademyAudiences.GENERAL);

    const role = isAcademy ? UserRolesEnum.ACADEMY : UserRolesEnum.FAMILIAR;

    const user = {
      audience,
      isAcademy,
      role,
    };

    const userData: UserInterface = isAcademy
      ? await this.academiesService.getAcademySync(id)
      : await this.userService.getUserSync(id);

    Object.assign(user, userData);

    if (isAcademy) {
      Object.assign(user, this.checkAcademyData(user));
    }

    this.updateUserStore(user);
  }

  private checkAcademyData(user: UserInterface): Partial<UserInterface> {
    // General data check
    const needGeneralData: boolean = this.needsGeneralData(user);

    // Other data
    const needOtherData: boolean = !user.reservationMail;

    // Responsibles
    const needResponsibles: boolean = !user.responsibles?.length;

    return {
      needGeneralData,
      needOtherData,
      needResponsibles,
    };
  }

  private needsGeneralData(user: UserInterface) {
    return (
      !user.location ||
      !user.name ||
      !user.nif ||
      !user.companyType ||
      !user.phone
    );
  }

  protected updateTokensStore(tokens: TokenInterface): void {
    this.storage.create({ key: 'tokens', value: tokens });

    this.currentToken = tokens.accessToken;
    this.currentRefreshToken = tokens.refreshToken;

    this.dataStore.tokens = tokens;
    this.updateObservable(this.observables.tokens, this.dataStore.tokens);
  }

  protected updateUserStore(user: UserInterface): void {
    this.storage.create({ key: 'user', value: user });

    this.currentUser = user;
    this.dataStore.user = user;

    this.updateObservable(this.observables.user, this.dataStore.user);
  }

  protected loginError(error: HttpErrorResponse): void {
    this.logout();
    this.errorsService.create(this.errorsSection, { payload: error });
  }

  public logout(): void {
    this.cleanDataStore();
  }

  refresh(
    updateUser: boolean = true,
    refreshFromStore = '',
    userFromStore?: UserInterface,
  ): void {
    const refreshToken = this.dataStore.tokens.refreshToken ?? refreshFromStore;

    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${refreshToken}`,
    );

    this.isRefreshing = true;
    this.updateObservable(this.observables.refreshing, true);
    this.http
      .post(this.baseUrlRefreshToken, {}, { observe: 'response', headers })
      .subscribe({
        next: async (response: HttpResponse<ExtendedRecordset<any>>) => {
          const data = response.body.data;
          const tokenDecoded: JWTToken = this.parseJWT(data.accessToken);
          const tokens: TokenInterface = this.processTokens(data, tokenDecoded);
          this.updateTokensStore(tokens);

          this.isRefreshing = false;
          this.updateObservable(this.observables.refreshing, false);

          if (updateUser && (this.currentUser || userFromStore)) {
            await this.refreshUser(
              this.currentUser?.id ?? userFromStore?.id,
              this.currentUser?.audience ?? userFromStore?.audience,
            );
          }
        },
        error: (error: HttpErrorResponse) => {
          const message = error.error.message;
          if (message === 'Invalid token') {
            const code = 'tokenexpired';
            const payload = { code };
            this.errorsService.create('errors.invalid-token', { payload });
            this.logout();
          }
          this.updateObservable(this.observables.refreshing, false);
          this.isRefreshing = false;
        },
      });
  }

  // loadTokenFromStorageOrSession
  loadTokens(): void {
    const userKey = 'user';
    const tokensKey = 'tokens';
    const userSection = 'loadUser';
    const tokensSection = 'loadToken';

    forkJoin({
      storageTokens: this.storage.getObservable(tokensSection).pipe(
        filter((tokens: any) => !tokens.loading),
        map((storage: any) => storage.tokens),
        take(1),
      ),
      storageUser: this.storage.getObservable(userSection).pipe(
        filter((user: any) => !user.loading),
        map((storage: any) => storage.user),
        take(1),
      ),
    }).subscribe(({ storageTokens, storageUser }: StorageData) => {
      if (
        !!storageUser &&
        !!storageTokens &&
        !this.isTokenExpired(storageTokens, storageUser)
      ) {
        this.updateTokensStore(storageTokens);
        this.updateUserStore(storageUser);
        this.refresh(true);
      }

      this.updateObservable(this.observables.storeLoaded, true);
    });

    this.storage.load(tokensKey, tokensSection);
    this.storage.load(userKey, userSection);
  }

  protected isTokenExpired(tokens: Token, user: UserInterface): boolean {
    const unixDate = Math.floor(Date.now() / 1000);
    const isExpired = tokens.expiresIn <= unixDate;
    if (isExpired || !tokens.expiresIn) {
      this.handleTokenExpired(user, tokens);
    }
    return isExpired;
  }

  protected handleTokenExpired(
    user: UserInterface,
    token: TokenInterface,
  ): void {
    if (!user.isAcademy) {
      this.refresh(true, token.refreshToken, user);
      return;
    }

    this.logout();
  }

  protected cleanDataStore(): void {
    this.storage.remove('tokens');
    this.storage.remove('user');

    this.dataStore.user = undefined;
    this.currentUser = undefined;
    this.updateObservable(this.observables.user, {});

    this.dataStore.tokens = undefined;
    this.currentRefreshToken = undefined;
    this.currentToken = undefined;
    this.updateObservable(this.observables.tokens, {});
  }

  public abstract loginLinkedIn(): Promise<void>;

  public abstract loginFacebook(): Promise<void>;

  public abstract loginGoogle(): Promise<void>;
}
