import { Injectable } from '@angular/core';
import {
  PRIMARY_OUTLET,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { UserInterface, UserRolesEnum } from '@interfaces/user/user.interface';
import { NavController } from '@ionic/angular';
import { AbstractAuthGuard } from '@services/abstract/abstract.auth.guard';
import { AuthService } from '@services/auth/auth.service';

@Injectable()
export class RoleGuard extends AbstractAuthGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected navCtrl: NavController,
  ) {
    super(authService, router);
  }

  protected check(user: UserInterface, state: RouterStateSnapshot): boolean {
    const urlParsed: UrlTree = this.router.parseUrl(state.url);
    const urlPrimary: UrlSegmentGroup = urlParsed.root.children[PRIMARY_OUTLET];
    const urlSegments: UrlSegment[] = urlPrimary.segments;

    const urlRole: string = urlSegments[1].path;
    const urlHaveRole: boolean = this.checkRole(urlRole as UserRolesEnum);

    if (!urlHaveRole) {
      const urlSegmentsMap: string[] = urlSegments.map(
        (segment) => segment.path,
      );
      urlSegmentsMap.splice(1, 0, user.role);
      const newPath: UrlTree = this.router.createUrlTree(urlSegmentsMap);
      this.navCtrl.navigateRoot(newPath);
      return false;
    }

    const childNotDefined: boolean = urlSegments.length === 2;

    if (urlRole !== user.role || childNotDefined) {
      this.navCtrl.navigateRoot('/admin');
      return false;
    }

    return true;
  }

  private checkRole(role: UserRolesEnum): boolean {
    return [UserRolesEnum.FAMILIAR, UserRolesEnum.ACADEMY].includes(role);
  }

  protected onError(error): boolean {
    return false;
  }
}
