<div class="modal-body modal-login">
  <span class="modal-content__title" translate>public.modal-user.title</span>

  <form
    class="form mt-40 mb-30"
    [ngClass]="{'form--loading': loginLoading}"
    [formGroup]="loginForm"
    (keydown.enter)="doLogin()"
  >
    <app-loading [loadingStatus]="loginLoading"></app-loading>
    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!loginForm.controls.email.valid && loginForm.controls.email.dirty"
      >
        <ion-input
          name="email"
          type="email"
          formControlName="email"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.email' | translate"
        ></ion-input>
      </ion-item>

      <app-error-input-form
        [showError]="!loginForm.controls.email.valid && loginForm.controls.email.dirty"
        [form]="loginForm"
        [item]="'email'"
      ></app-error-input-form>
    </div>

    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!loginForm.controls.password.valid && loginForm.controls.password.dirty"
      >
        <ion-input
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.password' | translate"
        ></ion-input>

        <ion-button
          (click)="showPassword = !showPassword"
          class="button button--circle button--no-bg"
        >
          <i
            class="fa-light"
            [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
        </ion-button>
      </ion-item>

      <app-error-input-form
        [showError]="!loginForm.controls.password.valid && loginForm.controls.password.dirty"
        [form]="loginForm"
        [item]="'password'"
      ></app-error-input-form>
    </div>
  </form>

  <ion-grid>
    <ion-row>
      <ion-col size="12" class="mb-10">
        <span (click)="goForgotPassword()" class="modal-content__link" translate
          >public.modal-user.forgot</span
        >
      </ion-col>
      <ion-col size="12" class="mb-10">
        <ion-grid>
          <ion-row class="ion-justify-content-center ion-align-items-center">
            <ion-col size="auto">
              <ion-button
                [disabled]="this.loginForm.invalid || loginLoading"
                (click)="doLogin()"
                class="button button--normal button--login"
                translate
              >
                session.login
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col size="12">
        <span class="mr-5" translate>public.modal-user.noaccount</span>
        <span (click)="goSignup()" class="modal-content__link" translate
          >session.signup</span
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
