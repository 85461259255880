import { Injectable } from '@angular/core';
import {
  ReservationData,
  ReservationType,
} from '@interfaces/checkout/checkout.interface';
import { ReservationInterface } from '@interfaces/reservations/reservations.interface';
import { ReservationsQueryParams } from '@interfaces/reservations/reservations.queryParams';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class ReservationsService extends AbstractService<
  any,
  ReservationsQueryParams
> {
  protected errorsSection = 'errors.reservations';
  protected endPoint = '/activities/%s/reservations';

  public reservationRequest(
    reservationType: ReservationType,
    activityId: string,
    reservationData: ReservationData,
    section: string,
  ) {
    const endPoint = `/activities/%s/${reservationType}`;
    this.create(reservationData, section, [activityId], endPoint);
  }

  public findReservations(
    queryParams: ReservationsQueryParams,
    section: string,
  ): void {
    const endPoint = '/reservations';
    this.getAll(queryParams, section, [], endPoint);
  }

  public findAcademyReservations(
    queryParams: ReservationsQueryParams,
    section: string,
  ): void {
    const endPoint = '/academy-reservations';
    this.getAll(queryParams, section, [], endPoint);
  }

  public findUserReservations(
    queryParams: ReservationsQueryParams,
    section: string,
  ): void {
    const endPoint = '/user-reservations';
    this.getAll(queryParams, section, [], endPoint);
  }

  public getReservationStats(
    queryParams: ReservationsQueryParams,
    section: string,
  ): void {
    const endPoint = '/reservations/stats';
    this.getAll(queryParams, section, [], endPoint);
  }
}
