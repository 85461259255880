import { Injectable } from '@angular/core';
import { FaqsInterface } from '@interfaces/faqs/faqs.interface';
import { FaqsQueryParamsInterface } from '@interfaces/faqs/faqs.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';
import { Observable } from 'rxjs';

@Injectable()
export class FaqsService extends AbstractService<
  FaqsInterface,
  FaqsQueryParamsInterface
> {
  protected errorsSection = 'errors.faqs';
  protected endPoint = '/faq-sections/';

  public override getObservable(
    section = 'standard',
  ): Observable<FaqsInterface[]> {
    return super.getObservable(section);
  }
}
