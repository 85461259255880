import { AdminViewConfig } from '@interfaces/config/config.view.interface';
import { CryptoInterface } from '@interfaces/crypto/crypto.interface';
import { AnimationController, ToastOptions, isPlatform } from '@ionic/angular';
import { IonicConfig } from '@ionic/core';
import { StorageConfig } from '@ionic/storage';
import { BrowserOptions } from '@sentry/browser';

const sentry: BrowserOptions = {
  dsn: '',
  debug: false,
  enabled: true,
};

const storageDB: StorageConfig = {
  name: 'becubs',
};

const toaster: ToastOptions = {
  position: 'top',
  duration: 3000,
  //showCloseButton: false,
  //closeButtonText: 'Done',
};

const ionic: IonicConfig = {
  mode: 'ios',
  navAnimation: isPlatform('mobileweb')
    ? null
    : (_) => new AnimationController().create(),
};

const view: AdminViewConfig = {
  inManagementMenu: false,
  inMenu: false,
  showLeftMenu: true,
};

const crypto: CryptoInterface = {
  algorithm: 'aes-256-ctr',
  iv: 'ThWmZq4t7w!z%C*F',
  key: 'F)J@NcRfUjWnZr4u7x!A%D*G-KaPdSgV',
  cipher_input_encoding: 'utf-8',
  cipher_output_encoding: 'hex',
};

export const environmentBase = {
  api: '',
  apiKey: '',
  googleApiKey: 'AIzaSyBh2j-bzF2vwf7JKmFeZECj5CHb7pR7HFM',
  cookieDomain: '',
  production: false,
  static: '',
  crypto,
  sentry,
  toaster,
  storageDB,
  ionic,
  view,
  /*
  ladda: {
    style: 'zoom-in',
    spinnerSize: 40,
    spinnerColor: 'white',
    spinnerLines: 12,
  },
  socialAuth: {},
  */
};

/* 
environmentBase.socialAuth = {
  baseUrl: environmentBase.api,
  providers: {
    linkedin: {
      url: 'tokens',
      clientId: '86az306lssh0h3',
    },
    facebook: {
      url: 'tokens',
      clientId: '1999260230336024',
    },
    google: {
      url: 'tokens',
      clientId: '392164456106-3jtkjfjc6hv92o1ccjqd053ktgu5ksls',
    },
  },
}; 
*/
