import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { MediumService } from './medium.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class MediumServiceModule {
  static forRoot(): ModuleWithProviders<MediumServiceModule> {
    return {
      ngModule: MediumServiceModule,
      providers: [MediumService],
    };
  }
}
