import { FormControl, FormGroup } from '@angular/forms';
import validator from 'validator';

export function checkTime() {
  return (input: FormControl) => {
    const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
      input.value,
    );
    if (!input.value) {
      return null;
    }
    return isValid ? null : { ['checkTime']: true };
  };
}

export function checkDate() {
  return (input: FormControl) => {
    const isValid = /^[0-9]{4}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{2}$/.test(
      input.value,
    );
    if (!input.value) {
      return null;
    }
    return isValid ? null : { ['checkDate']: true };
  };
}

export function checkYear() {
  return (input: FormControl) => {
    const isValid = /^(\d{4})$/.test(input.value);
    if (!input.value) {
      return null;
    }
    return isValid ? null : { ['checkYear']: true };
  };
}

export function minLengthCustom(minLength: number) {
  return (input: FormControl) => {
    const length = input.value ? input.value.length : 0;
    if (!input.value) {
      return null;
    }
    return length > minLength ? null : { ['minLengthCustom']: true };
  };
}

export function checkCreditCard() {
  return (input: FormControl) => {
    const isValid = validator.isCreditCard(input.value);
    if (!input.value) {
      return null;
    }
    return isValid ? null : { ['checkCreditCard']: true };
  };
}

// This custom validator check if password have one number, one special character and one capital letter
export function checkPasswordCustom() {
  return (input: FormControl) => {
    const isValid = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W|_])[\WA-Za-z\d_]{8,}$/.test(
      input.value,
    );

    if (!input.value) {
      return null;
    }
    return isValid ? null : { ['checkPasswordCustom']: true };
  };
}

export function checkSamePasswords() {
  return (input: FormGroup) => {
    const { password, passwordConfirm } = input?.controls;
    let isValid = true;
    if (password?.valid && passwordConfirm?.valid) {
      isValid = password.value === passwordConfirm.value;
    }

    if (!input.valid) {
      return null;
    }

    return isValid ? null : { ['checkSamePasswords']: true };
  };
}

export function checkEmail() {
  return (input: FormControl) => {
    const isValid = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(
      input.value,
    );
    if (!input.value) {
      return null;
    }
    return isValid ? null : { ['checkEmail']: true };
  };
}

export function checkNIF() {
  return (input: FormControl) => {
    if (!input.value) {
      return null;
    }

    const isValid = /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/.test(
      input.value,
    );

    return isValid ? null : { ['checkNIF']: true };
  };
}

export function checkLuhn() {
  return (input: FormControl) => {
    if (!input.value) {
      return null;
    }

    const isLuhnValid = luhnValidate(input.value);

    return isLuhnValid ? null : { ['luhn']: true };
  };
}

export function luhnValidate(str: string): boolean {
  if (!str) {
    str = '';
  }
  str = str.toString();
  str = str.replace(/\D/g, '');

  let isEven = false;
  let sum = 0;
  for (let i = str.length - 1; i >= 0; i -= 1) {
    let num = Number.parseInt(str[i], 10);
    if (isEven) {
      num *= 2;
      num = num > 9 ? num - 9 : num;
    }
    sum += num;
    isEven = !isEven;
  }
  return sum % 10 === 0;
}

export function minMax(min: number, max: number) {
  return (input: FormControl) => {
    const value = input.value;

    const isValid = value >= min && value <= max;

    return isValid ? null : { ['minMax']: true };
  };
}
