import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  fromEvent,
  distinctUntilChanged,
  debounce,
  timer,
} from 'rxjs';

interface ScreenSize {
  width: number;
  height: number;
  isMobile: boolean;
  breakpoint: string;
}

export const breakPoints = {
  xs: 0,
  sm: 389,
  md: 576,
  lg: 767,
  xl: 992,
};

@Injectable()
export class ScreenService {
  private defaultScreenSize: ScreenSize = this.checkScreen();

  private screenSizeSubject: BehaviorSubject<ScreenSize> = new BehaviorSubject(
    this.defaultScreenSize,
  );

  public screenSize: Observable<ScreenSize> =
    this.screenSizeSubject.asObservable();

  constructor() {
    fromEvent(window, 'resize')
      .pipe(
        distinctUntilChanged(),
        debounce(() => timer(50)),
      )
      .subscribe(() => {
        const newSize: ScreenSize = this.checkScreen();
        this.screenSizeSubject.next(newSize);
      });
  }

  checkScreen(): ScreenSize {
    const width = window.innerWidth;
    const [breakpoint] = Object.keys(breakPoints)
      .filter((key) => breakPoints[key] < width)
      .slice(-1);
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: innerWidth <= breakPoints.lg,
      breakpoint,
    };
  }
}
