import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapsService } from './google-maps.service';

@NgModule({
  imports: [CommonModule],
})
export class GoogleMapsServiceModule {
  static forRoot(): ModuleWithProviders<GoogleMapsServiceModule> {
    return {
      ngModule: GoogleMapsServiceModule,
      providers: [GoogleMapsService],
    };
  }
}
