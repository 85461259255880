<div
  class="inner-content ion-text-center"
  [ngClass]="{
    'modal--bg-login': !isModalAdventages,
    'modal--bg-business': isModalAdventages
  }"
>
  <header
    class="modal-content__header mb-30"
    [ngClass]="{
      'modal-content__header--business': isModalAdventages
    }"
  >
    <ion-button (click)="closeMenu()" class="button button--close" fill="clear">
      <i class="fa-thin fa-circle-xmark"></i>
    </ion-button>

    <span
      *ngIf="!isModalAdventages"
      class="modal-content__header-title"
      translate
      >{{ headerTitle }}</span
    >
  </header>

  <div [class.pb-50]="isModalAdventages" class="modal-content__body">
    <swiper #slides [config]="userSlidesConfig" *ngIf="userSlidesConfig">
      <!-- Forgot password -->
      <ng-template swiperSlide>
        <app-forgot-password-slide></app-forgot-password-slide>
      </ng-template>

      <!-- Login -->
      <ng-template swiperSlide>
        <app-login-slide (action)="slideTo($event)"></app-login-slide>
      </ng-template>

      <!-- Pre-Signup -->
      <ng-template swiperSlide>
        <app-pre-signup-slide (action)="slideTo($event)"></app-pre-signup-slide>
      </ng-template>

      <!-- Signup -->
      <ng-template swiperSlide>
        <app-signup-slide></app-signup-slide>
      </ng-template>

      <!-- Signup Academy -->
      <ng-template swiperSlide>
        <app-signup-academy-slide
          (action)="slideTo($event)"
        ></app-signup-academy-slide>
      </ng-template>
    </swiper>
  </div>
</div>
