import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import {
  PressReleaseInterfaceResponse,
  PressReleaseQueryParams,
} from '@interfaces/press-release/press-release.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class PressReleaseService extends AbstractService<
  PressReleaseInterfaceResponse,
  PressReleaseQueryParams
> {
  protected errorsSection = 'errors.press-releases';
  protected endPoint = '/press-releases/';
}
