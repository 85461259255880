<div class="inner-content ion-text-center modal--bg-login">
  <header class="onboarding-modal__header mb-30">
    <ion-button (click)="closeMenu()" class="button button--close" fill="clear">
      <i class="fa-thin fa-circle-xmark"></i>
    </ion-button>
    <span class="onboarding-modal__header-title" translate>{{titleKey}}</span>
  </header>
  <div class="onboarding-modal__body ion-text-center">
    <div *ngFor="let message of messageKeys" translate> {{ message }} </div>

    <ion-button
      (click)="logout()"
      class="mt-25 button button--outline button--md mt-5"
      fill="outline"
    >
      {{ 'session.logout' | translate }}
      <i class="button__icon--big fa-regular fa-arrow-right-from-bracket"></i>
    </ion-button>
  </div>
</div>
