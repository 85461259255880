import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  PoliciesParsedInterface,
  PolicyType,
} from '@interfaces/policies/policies.interface';
import { PoliciesService } from '@services/policies/policies.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import {
  resolvePath,
  updateObservable,
} from '@shared/utils/helpers/basic-observable.helper';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { AuthService } from '@services/auth/auth.service';
import { PublicObservableSections } from '@app/modules/public/constants/public.basic-ob.sections';
import { NavController } from '@ionic/angular';
import { ModalService } from '@services/modal/modal.service';
import { LogoutMessageModalComponent } from '../logout-message-modal/logout-message-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {
  public policyType = PolicyType;

  public policies: PoliciesParsedInterface;

  public legalTermsPolicy: string = '#';
  public userTermsPolicy: string = '#';
  public privacyPolicy: string = '#';
  public cookiesPolicy: string = '#';
  public academyTermsPolicy: string = '#';

  private policiesSub: Subscription;

  constructor(
    private policiesService: PoliciesService,
    public translate: TranslateService,
    private bOBService: BasicObservableService,
    private authService: AuthService,
    private navCtrl: NavController,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.policiesSub = combineLatest({
      policies: this.policiesService.getPolicies(),
      language: this.translate.onLangChange,
    }).subscribe(({ policies, language }) => {
      const lang = language.lang.toUpperCase();

      this.legalTermsPolicy =
        resolvePath(policies, `${PolicyType.LEGAL_ADVERTISEMENT}.${lang}`) ??
        '#';

      this.userTermsPolicy =
        resolvePath(
          policies,
          `${PolicyType.USER_TERMS_AND_CONDITIONS}.${lang}`,
        ) ?? '#';

      this.privacyPolicy =
        resolvePath(policies, `${PolicyType.PRIVACY}.${lang}`) ?? '#';

      this.cookiesPolicy =
        resolvePath(policies, `${PolicyType.COOKIES}.${lang}`) ?? '#';

      this.academyTermsPolicy =
        resolvePath(
          policies,
          `${PolicyType.ACADEMY_TERMS_AND_CONDITIONS}.${lang}`,
        ) ?? '#';

      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.policiesSub.unsubscribe();
  }

  knowMore(): void {
    if (!this.authService.isLogged) {
      updateObservable(
        this.bOBService,
        PublicObservableSections.SHOW_MODAL,
        'academysignup',
      );
    } else if (this.authService.currentUser.isAcademy) {
      this.navCtrl.navigateRoot('admin/academy/management/activities');
    } else {
      this.showLogoffModal();
    }
  }

  private async showLogoffModal(): Promise<void> {
    await this.modalService.openModal(
      'familiar-modal',
      LogoutMessageModalComponent,
      'modal modal--user',
      {
        titleKey: 'public.messages.work_with_you',
        messageKeys: ['public.messages.onlynousercansignup'],
        openLoginWhenDismiss: false,
      },
      ({ data }) => {
        if (!!data?.logOff) {
          updateObservable(
            this.bOBService,
            PublicObservableSections.SHOW_MODAL,
            'academysignup',
          );
        }
      },
    );
  }
}
