<div class="modal-body modal-login">
  <span class="modal-content__title" translate>public.modal-user.forgot</span>

  <form
    class="form mt-30"
    [formGroup]="forgotForm"
    [ngClass]="{'form--loading': (forgotLoading$ | async)}"
    (keydown.enter)="doForgotPassword()"
  >
    <app-loading [loadingStatus]="(forgotLoading$ | async)"></app-loading>
    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!this.forgotForm.controls.email.valid && forgotForm.controls.email.dirty"
      >
        <ion-input
          formControlName="email"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.email' | translate"
        ></ion-input>
      </ion-item>

      <app-error-input-form
        [showError]="!this.forgotForm.controls.email.valid && forgotForm.controls.email.dirty"
        [form]="forgotForm"
        [item]="'email'"
      ></app-error-input-form>
    </div>
  </form>

  <ion-grid>
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size="auto">
        <ion-button
          [disabled]="forgotForm.invalid || (forgotLoading$ | async)"
          (click)="doForgotPassword()"
          class="button button--login"
          translate
        >
          public.modal-user.send
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
