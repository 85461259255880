import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

interface ErrorCodes {
  [key: number | string]: string;
}

const defaultErrors: ErrorCodes = {
  0: 'errors.auth.backdown',
  500: 'errors.auth.backdown',
  1000: 'errors.message.tokenexpired',
  401: 'errors.message.tokenexpired',
  default: 'errors.message.unknown',
};

@Injectable()
export class IonicToasterService {
  public message: string;
  private toast: HTMLIonToastElement;
  private options: ToastOptions;

  constructor(
    public toasterIonic: ToastController,
    private translate: TranslateService,
  ) {
    this.options = environment.toaster;
  }

  async presentToastWithOptions(
    message: string,
    className: string,
    externalOpts?: ToastOptions,
  ) {
    const opts = externalOpts ? externalOpts : this.options;
    opts.message = message;
    opts.cssClass = className;

    this.toast = await this.toasterIonic.create(opts);
    this.toast.present();

    const wrapperStyle: CSSStyleSheet = new CSSStyleSheet();
    wrapperStyle.replaceSync('.toast-wrapper { position: sticky }');

    const toastShadowRoot: ShadowRoot = this.toast.shadowRoot;
    toastShadowRoot.adoptedStyleSheets = [
      ...toastShadowRoot.adoptedStyleSheets,
      wrapperStyle,
    ];
  }

  toasterError(statusCode: string | number, errorCodes: ErrorCodes = {}): void {
    const errorMessages = {
      ...defaultErrors,
      ...errorCodes,
    };

    const errorMessage = errorMessages[statusCode] || errorMessages.default;

    const message = this.translate.instant(errorMessage);

    if (statusCode === 999) {
      this.presentToastWithOptions(message, 'toast toast--error');
      return;
    }
    this.presentToastWithOptions(`Error: ${message}`, 'toast toast--error');
  }

  toasterSuccess(message: string): void {
    const successMsg = this.translate.instant(message);

    this.presentToastWithOptions(successMsg, 'toast toast--success');
  }
}
