import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { getPlatform } from '@interfaces/platform/platform.enum';
import { UserInterface, UserRolesEnum } from '@interfaces/user/user.interface';
import { NavController } from '@ionic/angular';
import { AbstractAuthGuard } from '@services/abstract/abstract.auth.guard';
import { AuthService } from '@services/auth/auth.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { updateObservable } from '@shared/utils/helpers/basic-observable.helper';

@Injectable()
export class UserGuard extends AbstractAuthGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected navCtrl: NavController,
    protected bOBService: BasicObservableService,
  ) {
    super(authService, router);
  }

  protected check(user: UserInterface, state: RouterStateSnapshot): boolean {
    const isLogged: boolean = this.authService.isLogged;

    if (!isLogged) {
      const notPublic: boolean = !this.router.url.startsWith('/public');
      const nextRouteIsAdmin: boolean = state.url.startsWith('/admin');

      if (notPublic) {
        this.navCtrl.navigateRoot('/public');
      }

      const [lastUrlSegment] = state.url.split('/').slice(-1);
      const isCheckout = !notPublic && lastUrlSegment === 'checkout';

      if (isCheckout || nextRouteIsAdmin) {
        updateObservable(this.bOBService, 'show-modal', 'login');
      }

      return false;
    }

    return true;
  }

  protected onError(error): boolean {
    return false;
  }
}
