import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
// services
import { AuthHttpInterceptor } from '../auth/auth.http.interceptor';
import { AcademiesService } from './academies.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class AcademiesServiceModule {
  static forRoot(): ModuleWithProviders<AcademiesServiceModule> {
    return {
      ngModule: AcademiesServiceModule,
      providers: [AcademiesService],
    };
  }
}
