import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  distinctUntilKeyChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { categoryTypes, categories, subCategories } from './mocked-data';
import { MenuModalComponent } from './menu-modal/menu-modal.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { ModalService } from '@services/modal/modal.service';
import { OnBoardingModalComponent } from './onboarding-modal/onboarding-modal.component';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import {
  createObservable,
  getPayload,
} from '@shared/utils/helpers/basic-observable.helper';
import { NavController } from '@ionic/angular';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { UserInterface, UserRolesEnum } from '@interfaces/user/user.interface';
import { CategoriesModalComponent } from '../categories-modal/categories-modal.component';
import { ScreenService } from '@services/screen/screen.service';
import { PublicObservableSections } from '@app/modules/public/constants/public.basic-ob.sections';
import { SlidesActions } from './user-modal/components/interfaces/slide-actions.enum';
import { LogoutMessageModalComponent } from '../logout-message-modal/logout-message-modal.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: 'toolbar.template.html',
  styleUrls: ['./toolbar.style.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public showCategories: boolean;
  public categoryActive: string;

  public categoryTypes = categoryTypes;
  public categories = categories;
  public subCategories = subCategories;

  public user: UserInterface;
  public isLogged: boolean;

  public redirectUrl: string;

  public logoSize = {};

  constructor(
    private screenService: ScreenService,
    private authService: AuthService,
    private translate: TranslateService,
    private modalService: ModalService,
    private bOBService: BasicObservableService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: RouterEvent) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        const url: string = event.url;
        const inCategory: boolean = url.startsWith('/public/category');
        this.showCategories = inCategory;
      });
  }

  ngOnInit() {
    this.authService
      .getObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user: UserInterface) => {
        this.user = user;
        this.redirectUrl =
          this.user?.role === UserRolesEnum.ACADEMY
            ? '/admin/academy/management/activities'
            : '/admin';
        this.isLogged = !!this.user?.id;
      });

    this.bOBService
      .getObservable(PublicObservableSections.SHOW_MODAL)
      .pipe(takeUntil(this.destroyed$), getPayload())
      .subscribe((modalName: string) => {
        this.bOBService.cleanSection(PublicObservableSections.SHOW_MODAL);
        this.showModal(modalName);
      });

    createObservable(
      this.bOBService,
      PublicObservableSections.SET_CATEGORY,
      null,
    );

    this.bOBService
      .getObservable(PublicObservableSections.SET_CATEGORY)
      .pipe(takeUntil(this.destroyed$), getPayload())
      .subscribe((categoryActive: string) => {
        this.showCategory(categoryActive);
        this.bOBService.cleanSection(PublicObservableSections.SET_CATEGORY);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private showCategory(category: string): void {
    this.categoryActive = category;
  }

  private showModal(modalName: string): void {
    const showModalByName = {
      onboarding: this.openOnBoardingModal.bind(this),
      login: this.openLoginModal.bind(this),
      familiar: this.openFamiliarModal.bind(this),
      academysignup: this.openAcademySignup.bind(this),
      usersignup: this.openUserSignup.bind(this),
    }[modalName];
    showModalByName();
  }

  async openFamiliarModal(): Promise<void> {
    await this.modalService.openModal(
      'familiar-modal',
      LogoutMessageModalComponent,
      'modal modal--user',
      {
        titleKey: 'public.messages.wesorry',
        messageKeys: [
          'public.messages.onlyfamiliarcanreserve',
          'public.messages.loginasfamiliar',
        ],
      },
    );
  }

  async openMenuModal(): Promise<void> {
    await this.modalService.openModal(
      'menu-modal',
      MenuModalComponent,
      'modal modal--fullscreen modal--menu-bg',
    );
  }

  async openUserSignup(): Promise<void> {
    await this.modalService.openModal(
      'user-modal',
      UserModalComponent,
      'modal modal--user',
      {
        initialSlide: SlidesActions.SIGNUP,
      },
    );
  }

  async openAcademySignup(): Promise<void> {
    await this.modalService.openModal(
      'user-modal',
      UserModalComponent,
      'modal modal--user',
      {
        initialSlide: SlidesActions.SIGNUP_ACADEMY,
      },
    );
  }

  async openLoginModal(): Promise<void> {
    await this.modalService.openModal(
      'user-modal',
      UserModalComponent,
      'modal modal--user',
    );
  }

  async openCategoriesModal(): Promise<void> {
    await this.modalService.openModal(
      'categories-modal',
      CategoriesModalComponent,
      'modal modal--fullscreen',
      {},
      (data) => {
        this.navCtrl.navigateRoot(['public', 'category', data.data]);
      },
    );
  }

  async openOnBoardingModal(): Promise<void> {
    await this.modalService.openModal(
      'onboarding-modal',
      OnBoardingModalComponent,
      'modal modal--user modal--bg-onboarding',
    );
  }

  public logout(): void {
    this.navCtrl.navigateRoot('/public');
    this.authService.logout();
  }
}
