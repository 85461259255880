import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LogoutMessageModalComponent } from './logout-message-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [IonicModule, CommonModule, TranslateModule],
  declarations: [LogoutMessageModalComponent],
  exports: [LogoutMessageModalComponent],
})
export class LogoutMessageModalModule {}
