import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, Observable } from 'rxjs';

export interface LocalStorage {
  key: string;
  value: any;
}

@Injectable()
export class LocalStorageService {
  private dataStore: {
    standard: any;
  };
  private observables: {
    standard: BehaviorSubject<any>;
  };
  private behaviorSubjects: {
    standard: Observable<any>;
  };

  constructor(private storage: Storage) {
    this.createStore();
    this.dataStore = {
      standard: {} as any,
    };
    this.observables = {
      standard: new BehaviorSubject({} as any) as BehaviorSubject<any>,
    };
    this.behaviorSubjects = {
      standard: this.observables.standard.asObservable(),
    };
  }

  private async createStore() {
    await this.storage.create();
  }

  private checkSection(section = 'standard', forze = false) {
    if (forze || !this.dataStore[section]) {
      this.dataStore[section] = { loading: true } as any;
      this.observables[section] = new BehaviorSubject({
        loading: true,
      } as any) as BehaviorSubject<any>;
      this.behaviorSubjects[section] = this.observables[section].asObservable();
    }
    return true;
  }

  async checkKey(key: string) {
    const keys = await this.storage.keys();
    return keys.includes(key);
  }

  getObservable(section = 'standard') {
    this.checkSection(section);
    return this.behaviorSubjects[section];
  }

  cleanSection(section = 'standard') {
    this.checkSection(section);
    this.dataStore[section] = {};
    this.observables[section].next(Object.assign({}, this.dataStore)[section]);
  }

  load(key: string, section = 'standard') {
    this.checkSection(section);
    this.storage.get(key).then((val) => {
      this.dataStore[section][key] = val;
      this.dataStore[section].loading = false;
      this.observables[section].next(
        Object.assign({}, this.dataStore)[section],
      );
    });
  }

  create(element: LocalStorage, section = 'standard') {
    this.checkSection(section);
    this.storage.set(element.key, element.value).then(() => {
      this.dataStore[section][element.key] = element.value;
      this.observables[section].next(
        Object.assign({}, this.dataStore)[section],
      );
    });
  }

  update(element: LocalStorage) {
    Object.keys(this.dataStore).forEach((section) => {
      if (this.dataStore[section][element.key]) {
        this.storage.set(element.key, element.value).then(() => {
          this.dataStore[section][element.key] = element.value;
          this.observables[section].next(
            Object.assign({}, this.dataStore)[section],
          );
        });
      }
    });
  }

  remove(key: string) {
    Object.keys(this.dataStore).forEach((section) => {
      if (this.dataStore[section][key]) {
        this.storage.remove(key).then(() => {
          delete this.dataStore[section][key];
          this.observables[section].next(
            Object.assign({}, this.dataStore)[section],
          );
        });
      }
    });
  }
}
