import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-input-form',
  templateUrl: 'error-input-form.html',
  styleUrls: ['./error-input-form.scss'],
})
export class ErrorInputFormComponent implements OnInit {
  @Input() showError: boolean;
  @Input() form: FormGroup;
  @Input() item: string; // Example: for subGroup: nameSubGroup.item
  currentItem: any;

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    // Split the element by the point and thus access the subgroup
    let auxCurrentItem = this.form.controls;
    this.item.split('.').forEach((ctrl, idx, array) => {
      if (idx === array.length - 1) {
        this.currentItem = auxCurrentItem[ctrl];
        return;
      }
      auxCurrentItem = (auxCurrentItem[ctrl] as any).controls;
    });
  }
}
