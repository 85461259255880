<ion-content class="modal-content modal-menu">
  <div class="modal-content__center">
    <div class="menu-header ion-justify-content-center mb-40">
      <i
        (click)="closeMenu()"
        class="button button--close-big fa-light fa-circle-xmark"
      >
      </i>
    </div>
    <div class="modal-categories__categories">
      <ng-container *ngFor="let category of categories">
        <div
          (click)="goActivityType(category.title)"
          class="modal-categories__category"
          [ngClass]="'welcome__card--' + category.title"
        >
          <i
            class="modal-categories__icon fa-thin"
            [ngClass]="'fa-' + category.icon"
          >
          </i>
          <span class="modal-categories__title" translate>
            {{ 'public.categories.' + category.title }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</ion-content>
