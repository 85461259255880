import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  createObservable,
  getPayload,
  resolvePath,
  setPath,
  updateObservable,
} from '@shared/utils/helpers/basic-observable.helper';
import { map, Observable, take } from 'rxjs';
import { BasicObservableService } from '../basic-observable/basic-observable.service';

@Injectable()
export class ConfigsService {
  private hash: string = 'actual-config';
  private config$: Observable<any>;

  constructor(protected bOBService: BasicObservableService) {
    this.loadConfig();
  }

  private loadConfig() {
    createObservable(this.bOBService, this.hash, environment);
    this.config$ = this.bOBService.getObservable(this.hash).pipe(getPayload());
  }

  get config(): Observable<any> {
    return this.config$;
  }

  public getConfigParam(paramRoute: string): Observable<any> {
    return this.config$.pipe(map((config) => resolvePath(config, paramRoute)));
  }

  public updateConfig(update: object) {
    this.config$.pipe(take(1)).subscribe((config) => {
      Object.assign(config, update);
      updateObservable(this.bOBService, this.hash, config);
    });
  }

  public updateConfigParam(paramRoute: string, value: any) {
    this.config$.pipe(take(1)).subscribe((config) => {
      const lastParam = resolvePath(config, paramRoute);
      if (lastParam === value) return;
      const updatedConfig = setPath(config, paramRoute, value);
      this.updateConfig(updatedConfig);
    });
  }
}
