import { ModuleWithProviders, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalService } from './modal.service';

@NgModule({
  imports: [IonicModule],
})
export class ModalServiceModule {
  static forRoot(): ModuleWithProviders<ModalServiceModule> {
    return {
      ngModule: ModalServiceModule,
      providers: [ModalService],
    };
  }
}
