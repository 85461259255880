import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@interfaces/global/queryParams.interface';
import { SessionInterface } from '@interfaces/sessions/sessions.interface';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class SessionsService extends AbstractService<
  SessionInterface,
  QueryParamsInterface
> {
  protected errorsSection = 'errors.sessions';
  protected endPoint = '/activities/%s/sessions/';
}
