import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CookiesBannerComponent } from './cookies-banner.component';

@NgModule({
  imports: [IonicModule, CommonModule],
  declarations: [CookiesBannerComponent],
  exports: [CookiesBannerComponent],
})
export class CookiesBannerModule {}
