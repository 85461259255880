export enum UserSections {
  AUTH_ERRORS = 'errors.auth',

  ACADEMY_REGISTER = 'academy-register',
  ACADEMY_NEW = 'new-academy',
  ACADEMY_ERRORS = 'errors.academies.new-academy',

  USER_NEW = 'new-user',
  USER_ERRORS = 'errors.user.new-user',

  FORGOT_SEND = 'forgot-password-send',
  FORGOT_SEND_ERRORS = 'errors.forgot-password.forgot-password-send',
  FORGOT_COMPLETE = 'forgot-password-complete',
  FORGOT_COMPLETE_ERRORS = 'errors.forgot-password.forgot-password-complete',
}
