import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
// services
import { AuthHttpInterceptor } from '@services/auth/auth.http.interceptor';
import { ForgotPasswordService } from './forgot-password.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class ForgotPasswordServiceModule {
  static forRoot(): ModuleWithProviders<ForgotPasswordServiceModule> {
    return {
      ngModule: ForgotPasswordServiceModule,
      providers: [ForgotPasswordService],
    };
  }
}
