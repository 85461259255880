import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import {
  checkEmail,
  checkPasswordCustom,
} from '@shared/utils/customValidators/custom.validators';
import {
  getElement,
  updateObservable,
} from '@shared/utils/helpers/basic-observable.helper';
import { filter, ReplaySubject, skip, takeUntil } from 'rxjs';
import { SlidesActions } from '../interfaces/slide-actions.enum';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { UserInterface } from '@interfaces/user/user.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { ModalService } from '@services/modal/modal.service';
import { NavController } from '@ionic/angular';
import { UserSections } from '../interfaces/user-sections.interface';
import { AcademyOnboardingStatus } from '@interfaces/academy/onboarding-status.enum';
@Component({
  selector: 'app-login-slide',
  templateUrl: 'login.template.html',
})
export class LoginSlideComponent implements OnInit, OnDestroy {
  @Output() action = new EventEmitter<string>();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  loginForm: FormGroup;
  showPassword: boolean;
  loginLoading: boolean;

  constructor(
    private authService: AuthService,
    private errorsService: ErrorsService,
    private translate: TranslateService,
    private toasterService: IonicToasterService,
    private bOBService: BasicObservableService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.prepareForm();

    const userAlreadyLogged = !!this.authService.currentUser;

    this.authService
      .getObservable()
      .pipe(
        takeUntil(this.destroyed$),
        filter((user: UserInterface) => !!user && !!user.id),
        skip(userAlreadyLogged ? 1 : 0),
      )
      .subscribe((user: UserInterface) => {
        this.authSuccess(user);
      });

    this.errorsService
      .getObservable(UserSections.AUTH_ERRORS)
      .pipe(takeUntil(this.destroyed$), getElement())
      .subscribe((error: ErrorServiceInterface) => {
        const errorData: HttpErrorResponse = error.payload;
        this.authError(errorData);
        this.errorsService.remove(error.id);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private prepareForm(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, checkEmail()])],
      password: [
        '',
        Validators.compose([Validators.required, checkPasswordCustom()]),
      ],
    });
  }

  doLogin() {
    if (this.loginForm.invalid) return;
    const loginCredentials = this.loginForm.value;
    this.loginLoading = true;
    this.authService.login(loginCredentials);
  }

  goSignup(): void {
    this.action.emit(SlidesActions.SIGNUP);
  }

  goForgotPassword(): void {
    this.action.emit(SlidesActions.FORGOT);
  }

  private showOnBoardingModal() {
    updateObservable(this.bOBService, 'show-modal', 'onboarding');
  }

  private authSuccess(user: UserInterface) {
    this.loginLoading = false;

    this.modalService.closeActiveModal();

    if (user.isAcademy) {
      this.redirectUserToOnboardingStage(user);
    } else if (!user?.childAges) {
      this.showOnBoardingModal();
    }
  }

  private redirectUserToOnboardingStage(user: UserInterface) {
    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_1)
      this.navCtrl.navigateRoot('/admin/academy/general');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_2)
      this.navCtrl.navigateRoot('/admin/academy/other');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_3)
      this.navCtrl.navigateRoot('/admin/academy/responsible');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_4)
      this.navCtrl.navigateRoot('/admin/academy/stripe');

    if (user.onboardingStatus === AcademyOnboardingStatus.STEP_5)
      this.navCtrl.navigateRoot('/admin/academy/management/activities');

    if (!user.onboardingStatus)
      this.navCtrl.navigateRoot('/admin/academy/general');
  }

  private authError(error: HttpErrorResponse) {
    this.loginLoading = false;

    const statusCode = error.status;

    const errorCodes = {
      401: 'errors.auth.invaliduser',
      404: 'errors.auth.invaliduser',
    };

    this.toasterService.toasterError(statusCode, errorCodes);
  }
}
