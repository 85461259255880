import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoriesApi } from '@interfaces/categories/categories.enum';
import {
  CategoriesInterface,
  MultiThemeInterface,
  ThemeInterface,
  ThemeTypes,
} from '@interfaces/categories/categories.interface';
import { CategoriesQueryParams } from '@interfaces/categories/categories.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { ErrorsService } from '@services/errors/errors.service';
import {
  createObservable,
  getAllData,
  getPayload,
  updateObservable,
} from '@shared/utils/helpers/basic-observable.helper';
import { Observable, filter, map, take } from 'rxjs';

export interface ThemesServiceInterface {
  [CategoriesApi.EXTRACURRICULAR]?: MultiThemeInterface[];
  [CategoriesApi.BIRTHDAYS]?: MultiThemeInterface[];
  [CategoriesApi.CAMPS]?: MultiThemeInterface[];
  [CategoriesApi.PLANSOFTHEDAY]?: MultiThemeInterface[];
}

enum CategoriesSections {
  GET_THEMES = 'themes-get',
  LOADED = 'themes-loaded',
  THEMES = 'themes-service',
}

@Injectable()
export class CategoriesService extends AbstractService<
  CategoriesInterface,
  CategoriesQueryParams
> {
  protected errorsSection = 'errors.categories';
  protected endPoint = '/categories/';

  public themes: Observable<ThemesServiceInterface> = this.bOBService
    .getObservable(CategoriesSections.THEMES)
    .pipe(
      getPayload(),
      filter((_) => !!_),
    );

  public loaded: Observable<boolean> = this.bOBService
    .getObservable(CategoriesSections.THEMES)
    .pipe(
      getPayload(),
      map((_) => Boolean(_)),
    );

  public syncThemes: ThemesServiceInterface = {};
  public syncLoaded: boolean;

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected bOBService: BasicObservableService,
  ) {
    super(http, errorsService);

    createObservable(this.bOBService, CategoriesSections.THEMES, null);
    createObservable(this.bOBService, CategoriesSections.LOADED, false);

    this.loadCategories();
  }

  private loadCategories(): void {
    this.getObservable(CategoriesSections.GET_THEMES)
      .pipe(
        getAllData(),
        take(1),
        map((themesData: any) => (themesData as CategoriesInterface).themes),
      )
      .subscribe((themes: ThemeInterface[]) => {
        this.syncThemes = this.processThemes(themes);
        this.syncLoaded = true;
        updateObservable(
          this.bOBService,
          CategoriesSections.THEMES,
          this.syncThemes,
        );
        updateObservable(this.bOBService, CategoriesSections.LOADED, true);
      });

    this.getAll({}, CategoriesSections.GET_THEMES);
  }

  private onlyUnique(value: string, index: number, array: Array<any>) {
    return array.indexOf(value) === index;
  }

  private processThemes(themes: ThemeInterface[]): any {
    const realThemes = Object.entries(CategoriesApi).reduce((prev, next) => {
      const [, category] = next;
      prev[category] = [];
      return prev;
    }, {});

    const addTheme = (
      theme: any,
      themeType: ThemeTypes,
      themeId: string = '',
    ): void => {
      const { name, nameTranslation, id } = theme;

      const imageURL = this.generateName(theme.nameTranslation);

      let tempTheme: MultiThemeInterface = {
        name,
        nameTranslation,
        id,
        imageURL,
        themeType,
      };

      if (themeType !== ThemeTypes.THEME) {
        tempTheme = {
          ...tempTheme,
          themeId,
        };
      }

      if (themeType === ThemeTypes.SUBTHEMECLASS) {
        tempTheme = {
          ...tempTheme,
          subthemeId: theme.subthemeId,
        };
      }

      theme.categories
        .filter(this.onlyUnique)
        .forEach((category) => realThemes[category].push(tempTheme));
    };

    themes.forEach((theme: MultiThemeInterface) => {
      addTheme(theme, ThemeTypes.THEME);
      const hasSubTheme = !!theme?.subthemes?.length;
      if (hasSubTheme) {
        theme.subthemes.forEach((subTheme) => {
          addTheme(subTheme, ThemeTypes.SUBTHEME, theme.id);
          const hasSubThemeClass = !!subTheme.subthemeClasses?.length;
          if (hasSubThemeClass) {
            subTheme.subthemeClasses.forEach((subThemeClass) => {
              addTheme(subThemeClass, ThemeTypes.SUBTHEMECLASS, theme.id);
            });
          }
        });
      }
    });

    return realThemes;
  }

  private generateName(name: string): string {
    const newName = name.toLowerCase().replace(/ /g, '').replace(/-/g, '');

    return newName;
  }

  public getThemesByCategory(
    category: CategoriesApi,
    themeType: ThemeTypes = ThemeTypes.THEME,
  ): MultiThemeInterface[] {
    return this.syncThemes[category]
      .filter((theme) => theme.themeType === themeType)
      .sort(this.sortThemes);
  }

  public getSubThemesByThemeId(
    category: CategoriesApi,
    themeId: string,
  ): MultiThemeInterface[] {
    return this.syncThemes[category]
      .filter(
        (theme) =>
          theme.themeId === themeId && theme.themeType === ThemeTypes.SUBTHEME,
      )
      .sort(this.sortThemes);
  }

  public getThemeIdBySubThemeId(
    category: CategoriesApi,
    subThemeId: string,
  ): string {
    return this.syncThemes[category].find(
      (theme) => theme?.subthemeId === subThemeId,
    ).themeId;
  }

  public getThemeById(
    category: CategoriesApi,
    id: string,
  ): MultiThemeInterface {
    return this.syncThemes[category].find((theme) => theme.id === id);
  }

  public sortThemes(a, b) {
    const sortPreference = ['Special Needs', 'Others'];
    return (
      sortPreference.indexOf(a.nameTranslation) -
      sortPreference.indexOf(b.nameTranslation)
    );
  }

  public getThemesPublished(
    params: CategoriesQueryParams,
    section: string,
  ): void {
    const endPoint = '/categories/published';
    this.getAll(params, section, [], endPoint);
  }
}
