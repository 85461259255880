import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractAuthService } from '@services/abstract/abstract.auth.service';
import { AcademiesService } from '@services/academies/academies.service';
import { BasicObservableService } from '@services/basic-observable/basic-observable.service';
import { ErrorsService } from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { UsersService } from '@services/users/users.service';
// import { AuthService as SocialAuthService } from 'ng2-ui-auth';

@Injectable()
export class AuthService extends AbstractAuthService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService,
    protected storage: LocalStorageService,
    protected session: SessionStorageService, // protected socialAuth: SocialAuthService,
    protected userService: UsersService,
    protected academiesService: AcademiesService,
    protected basicObservableService: BasicObservableService,
  ) {
    super(
      http,
      errorsService,
      storage,
      session,
      userService,
      academiesService,
      basicObservableService,
    );
  }

  public async loginLinkedIn() {
    // this.socialAuth.authenticate('linkedin').subscribe(
    //   (resp) => {
    //     this.loginSuccess(resp);
    //   },
    //   (error) => {
    //     this.loginError(error);
    //   },
    // );
  }

  public async loginFacebook() {
    // this.socialAuth.authenticate('facebook').subscribe(
    //   (resp) => {
    //     this.loginSuccess(resp);
    //   },
    //   (error) => {
    //     this.loginError(error);
    //   },
    // );
  }

  public async loginGoogle() {
    // this.socialAuth.authenticate('google').subscribe(
    //   (resp) => {
    //     this.loginSuccess(resp);
    //   },
    //   (error) => {
    //     this.loginError(error);
    //   },
    // );
  }
}
