import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { NavController, ToastController } from '@ionic/angular';
import { AuthService } from '@services/auth/auth.service';
import { Observable, fromEvent, throttleTime } from 'rxjs';

@Injectable()
export class ActivityChecker {
  private maxInactivityMinutes = environment.inactivityMaxMinutes;
  constructor(
    private authService: AuthService,
    private navCtrl: NavController,
    private toasterService: IonicToasterService,
  ) {}

  private userActivity$: Observable<Event> = fromEvent(
    document,
    'mousemove',
  ).pipe(throttleTime(2000));

  public setLastApiRequest() {
    sessionStorage.setItem('lastApiRequest', Date.now().toString());
  }

  public listenToUserActivity() {
    this.userActivity$.subscribe(() => {
      const lastApiRequest = sessionStorage.getItem('lastApiRequest');
      if (lastApiRequest && this.authService.currentUser) {
        const inactiveTime = Date.now() - Number(lastApiRequest);
        if (inactiveTime > this.maxInactivityMinutes * 60 * 1000) {
          this.handleUserInactive();
        }
      }
    });
  }

  private handleUserInactive() {
    this.authService.logout();
    this.navCtrl.navigateRoot('/');
    this.cleanLastApiRequestFromSessionStorage();
  }

  private cleanLastApiRequestFromSessionStorage() {
    sessionStorage.removeItem('lastApiRequest');
  }
}
