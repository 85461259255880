import { Injectable } from '@angular/core';
import { UserInterface } from '@interfaces/user/user.interface';
import { UserQueryParamsInterface } from '@interfaces/user/user.queryParams.interface';
import { AbstractService } from '@services/abstract/abstract.service';
import { getElement } from '@shared/utils/helpers/basic-observable.helper';
import { lastValueFrom, map, take, tap } from 'rxjs';

@Injectable()
export class UsersService extends AbstractService<
  UserInterface,
  UserQueryParamsInterface
> {
  protected errorsSection = 'errors.user';
  protected endPoint = '/users/';

  public async getUserSync(userId: string): Promise<UserInterface> {
    const section = 'get-user-sync';
    const endPoint = `${this.endPoint}%s`;
    this.getOne('', section, [userId], endPoint);
    return lastValueFrom(
      this.getObservable(section).pipe(
        getElement(),
        map((data: UserInterface) => data),
        tap(() => this.cleanSection(section)),
        take(1),
      ),
    );
  }

  public changePassword(
    userId: string,
    updatePasswordData: UserQueryParamsInterface,
    section: string,
  ): void {
    const endPoint = `${this.endPoint}${userId}/update-password`;
    this.update('', updatePasswordData, section, [], endPoint);
  }
}
