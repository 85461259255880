import { Injectable } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';

@Injectable()
export class ModalService {
  modal: HTMLIonModalElement;
  id: string;
  modalShown: boolean = false;
  waitingForClose: boolean = false;

  constructor(private modalController: ModalController) {}

  async closeActiveModal() {
    if (!this.modal) return;
    await this.modal.dismiss();
  }

  async openModal(
    id: string,
    component: any,
    cssClass: string,
    componentProps: object = {},
    modalDataCallback: (data: any) => void = null,
    options = null,
  ): Promise<void> {
    if (this.waitingForClose) return;

    if (this.modalShown) {
      try {
        this.waitingForClose = true;
        this.modal.dismiss();
        await this.asyncCallWithTimeout(this.modal.onDidDismiss(), 1000);
        this.waitingForClose = false;
        this.modalShown = false;
        if (this.id === id) {
          return;
        }
      } catch (e) {
        this.id = undefined;
        this.modal = undefined;
        this.modalShown = false;
      }
    }

    this.modalShown = true;
    this.id = id;
    this.modal = await this.modalController.create({
      component,
      componentProps,
      cssClass,
      ...options,
    });

    if (!!modalDataCallback) {
      this.modal.onWillDismiss().then((data) => modalDataCallback(data));
    }

    this.modal.present();
    await this.modal.onWillDismiss();
    this.modalShown = false;
  }

  async asyncCallWithTimeout(asyncPromise, timeLimit) {
    let timeoutHandle;

    const timeoutPromise = new Promise((_resolve, reject) => {
      timeoutHandle = setTimeout(() => reject(), timeLimit);
    });

    return Promise.race([asyncPromise, timeoutPromise]).then((result) => {
      clearTimeout(timeoutHandle);
      return result;
    });
  }
}
