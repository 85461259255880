<div class="modal-adventages">
  <span class="modal-adventages__title mb-20" translate
    >public.modal-user.becubsforbusiness</span
  >
  <span class="modal-adventages__subtitle mb-50" translate
    >public.modal-user.adventagestitle</span
  >
  <swiper
    class="modal-adventages__swiper mb-30"
    [config]="adventagesSwiperConfig"
  >
    <ng-template swiperSlide *ngFor="let adventagesCard of adventagesCards">
      <div class="modal-adventages__card">
        <svg-icon
          class="modal-adventages__card-icon"
          [src]="adventagesCard.icon"
        ></svg-icon>
        <span class="modal-adventages__card-title" translate
          >{{ adventagesCard.title }}</span
        >
        <span class="modal-adventages__card-description" translate
          >{{ adventagesCard.description }}</span
        >
      </div>
    </ng-template>
  </swiper>
  <form
    class="form mt-30 ion-align-self-center"
    [formGroup]="signupAcademyForm"
    (keydown.enter)="goAdventages()"
  >
    <app-loading [loadingStatus]="(signupLoading$ | async)"></app-loading>
    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!signupAcademyForm.controls.name.valid && signupAcademyForm.controls.name.dirty"
      >
        <ion-input
          formControlName="name"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.academyname' | translate"
        ></ion-input>
      </ion-item>

      <app-error-input-form
        [showError]="!signupAcademyForm.controls.name.valid && signupAcademyForm.controls.name.dirty"
        [form]="signupAcademyForm"
        item="name"
      ></app-error-input-form>
    </div>

    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!signupAcademyForm.controls.email.valid && signupAcademyForm.controls.email.dirty"
      >
        <ion-input
          type="email"
          formControlName="email"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.email' | translate"
        ></ion-input>
      </ion-item>

      <app-error-input-form
        [showError]="!signupAcademyForm.controls.email.valid && signupAcademyForm.controls.email.dirty"
        [form]="signupAcademyForm"
        item="email"
      ></app-error-input-form>
    </div>

    <div class="form__group">
      <ion-item
        class="form__item"
        [class.form__item--error]="!signupAcademyForm.controls.password.valid && signupAcademyForm.controls.password.dirty"
      >
        <ion-input
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          class="form__input form__input--md"
          [placeholder]="'public.modal-user.password' | translate"
        ></ion-input>

        <ion-button
          (click)="showPassword = !showPassword"
          class="button button--circle button--no-bg"
        >
          <i
            class="fa-light"
            [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
          ></i>
        </ion-button>
      </ion-item>

      <app-error-input-form
        [showError]="!signupAcademyForm.controls.password.valid && signupAcademyForm.controls.password.dirty"
        [form]="signupAcademyForm"
        item="password"
      ></app-error-input-form>
    </div>

    <div class="form__group mt-15 pb-0">
      <ion-item
        class="form__item form__item--checkbox"
        [class.form__item--error]="!signupAcademyForm.controls.terms.valid && signupAcademyForm.controls.terms.dirty"
      >
        <ion-label
          >{{ 'public.modal-user.agree' | translate }}
          <a [href]="policyTermsUrl | async" target="_blank" translate
            >public.modal-user.terms</a
          ></ion-label
        >
        <ion-checkbox
          [class.form__checkbox--error]="!signupAcademyForm.controls.terms.valid && signupAcademyForm.controls.terms.dirty"
          formControlName="terms"
          class="form__checkbox"
          slot="start"
        >
        </ion-checkbox>
      </ion-item>
    </div>

    <div class="form__group">
      <ion-item
        class="form__item form__item--checkbox"
        [class.form__item--error]="!signupAcademyForm.controls.policies.valid && signupAcademyForm.controls.policies.dirty"
      >
        <ion-label
          >{{ 'public.modal-user.agree' | translate }}
          <a [href]="policyPrivacyUrl | async" target="_blank" translate
            >public.modal-user.policy</a
          ></ion-label
        >
        <ion-checkbox
          [class.form__checkbox--error]="!signupAcademyForm.controls.policies.valid && signupAcademyForm.controls.policies.dirty"
          formControlName="policies"
          class="form__checkbox"
          slot="start"
        >
        </ion-checkbox>
      </ion-item>
    </div>
  </form>

  <ion-grid>
    <ion-row class="ion-justify-content-center ion-align-items-center mt-30">
      <ion-col size="auto">
        <ion-button
          [disabled]="this.signupAcademyForm.invalid || (signupLoading$ | async)"
          (click)="goAdventages()"
          class="button button--normal button--login"
          translate
          >session.signup
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
