import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReservationsService } from './reservations.service';

@NgModule({
  imports: [CommonModule],
})
export class ReservationsServiceModule {
  static forRoot(): ModuleWithProviders<ReservationsServiceModule> {
    return {
      ngModule: ReservationsServiceModule,
      providers: [ReservationsService],
    };
  }
}
