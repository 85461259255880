<div class="app">
  <ion-tab-bar class="app__header" slot="top">
    <app-toolbar class="app__toolbar"></app-toolbar>
  </ion-tab-bar>
  <app-cookies-banner></app-cookies-banner>
  <ion-app class="app__content">
    <div class="container-vertical">
      <div>
        <router-outlet></router-outlet>
      </div>

      <app-footer></app-footer>
    </div>
  </ion-app>
  <ion-tab-bar slot="bottom" class="app__tabs-container ion-hide-md-up">
    <ng-container *ngFor="let button of tabButtons">
      <ion-tab-button
        *ngIf="button.visible"
        [routerLink]="[button.route]"
        routerLinkActive="tab-selected"
        class="app__tab"
      >
        <i [class]="'app__tab-icon ' + button.icon"></i>
        <ion-label class="app__tab-label" translate
          >{{ button.title }}</ion-label
        >
      </ion-tab-button>
    </ng-container>
  </ion-tab-bar>
</div>
