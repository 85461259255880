import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper';
import { SlidesActions } from './components/interfaces/slide-actions.enum';
import { SwiperComponent } from 'swiper/angular';

export const UserModalSlices = {
  [SlidesActions.FORGOT]: 0,
  [SlidesActions.LOGIN]: 1,
  [SlidesActions.SIGNUP]: 2,
  [SlidesActions.SIGNUP_FAMILY]: 3,
  [SlidesActions.SIGNUP_ACADEMY]: 4,
};

@Component({
  selector: 'app-user-modal',
  templateUrl: 'user-modal.template.html',
})
export class UserModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('slides', { static: false }) userSlides?: SwiperComponent;
  @Input() initialSlide: SlidesActions = SlidesActions.LOGIN;

  public userSlidesConfig: SwiperOptions | null = null;

  public isModalAdventages: boolean;

  public headerTitle: string = 'public.modal-user.headertitle';

  public slideNext = UserModalSlices;

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this.initialSlide !== SlidesActions.LOGIN) {
      this.headerTitle =
        this.initialSlide === SlidesActions.FORGOT
          ? this.translate.instant('public.modal-user.recoveryourpassword')
          : this.translate.instant('session.signup');
    }
    if (this.initialSlide === SlidesActions.LOGIN) {
      this.headerTitle = this.translate.instant('session.login');
    }

    this.userSlidesConfig = {
      allowTouchMove: false,
      initialSlide: this.slideNext[this.initialSlide],
      spaceBetween: 4,
      autoHeight: true,
    };
  }

  ngOnDestroy() {}

  ngAfterViewInit(): void {
    if (this.initialSlide) {
      setTimeout(() => this.slideTo(this.initialSlide), 100);
    }
  }

  closeMenu() {
    this.modalCtrl.dismiss(null, 'close');
  }

  slideTo(moveToSlide: SlidesActions) {
    this.headerTitle =
      moveToSlide === SlidesActions.FORGOT
        ? this.translate.instant('public.modal-user.recoveryourpassword')
        : this.translate.instant('session.signup');

    const userModal: HTMLElement = document.querySelector('.modal--user');

    if (moveToSlide === SlidesActions.SIGNUP_ACADEMY) {
      this.isModalAdventages = true;
      this.renderer.addClass(userModal, 'modal--lg');
    } else {
      this.renderer.removeClass(userModal, 'modal--lg');
    }

    this.userSlides.swiperRef.slideTo(this.slideNext[moveToSlide]);
  }
}
