export enum PaymentSections {
  CHECK_RESERVATION = 'check-reservation',
  CHECK_RESERVATION_ERRORS = 'errors.reservations.check-reservation',
  MAKE_PAYMENT = 'make-payment',
  MAKE_PAYMENT_ERRORS = 'errors.stripe.make-payment',
}

export enum ModalSections {
  WILL_DISMISS = 'will-dismiss',
}
