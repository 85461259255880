<div class="inner-content ion-text-center onboarding-modal">
  <header class="onboarding-modal__header mb-30">
    <ion-button (click)="closeMenu()" class="button button--close" fill="clear">
      <i class="fa-thin fa-circle-xmark"></i>
    </ion-button>
    <span class="onboarding-modal__header-title" translate
      >public.modal-user.onboardingtitle</span
    >
  </header>
  <div class="onboarding-modal__body">
    <div
      class="onboarding-modal__age-selector"
      [ngClass]="{'form--loading': (userLoading | async)}"
    >
      <app-age-selector
        (selectedAgesChange)="agesChanged($event)"
      ></app-age-selector>
      <ion-button
        [disabled]="!agesSelected.length"
        (click)="updateChildAges()"
        class="button button--normal button--login"
        translate
      >
        public.modal-user.save
      </ion-button>
    </div>
  </div>
</div>
