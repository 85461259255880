import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';
import { filter, map, Observable } from 'rxjs';

function getPayload() {
  return function <T>(source: Observable<BasicObservable[]>): Observable<T> {
    return new Observable((subscriber) => {
      source
        .pipe(
          filter((data: BasicObservable[]) => !!data && !!data.length),
          map((data: BasicObservable[]) => data[data.length - 1].payload),
        )
        .subscribe({
          next(payload) {
            subscriber.next(payload);
          },
        });
    });
  };
}

function getAllData() {
  return function <T>(source: Observable<[]>): Observable<T> {
    return new Observable((subscriber) => {
      source
        .pipe(filter((data: any) => !!data && !Array.isArray(data)))
        .subscribe({
          next(data) {
            subscriber.next(data);
          },
        });
    });
  };
}

function getElement() {
  return function <T>(source: Observable<[]>): Observable<T> {
    return new Observable((subscriber) => {
      source
        .pipe(
          filter((data: T[]) => !!data && !!data.length),
          map((data: T[]) => data[data.length - 1]),
        )
        .subscribe({
          next(data) {
            subscriber.next(data);
          },
        });
    });
  };
}

function updateObservable(
  bOBService: BasicObservableService,
  hash: string,
  payload: any,
) {
  const basicData: BasicObservable = {
    hash,
    payload,
  };

  bOBService.update(basicData, hash);
}

function createObservable(
  bOBService: BasicObservableService,
  hash: string,
  payload: any,
) {
  const basicData: BasicObservable = {
    hash,
    payload,
  };

  bOBService.create(basicData, hash);
}

function setPath(object, path, value) {
  return path
    .split('.')
    .reduce(
      (o, p, i) => (o[p] = path.split('.').length === ++i ? value : o[p] || {}),
      object,
    );
}

function resolvePath(object, path, defaultValue = '#') {
  return path
    .split(/[\.\[\]\'\"]/)
    .filter((p) => p)
    .reduce((o, p) => (o ? o[p] : defaultValue), object);
}

export {
  getPayload,
  getAllData,
  getElement,
  updateObservable,
  createObservable,
  setPath,
  resolvePath,
};
