import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SearchComponent } from './search-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [ReactiveFormsModule, FormsModule, CommonModule, IonicModule],
  declarations: [SearchComponent],
  exports: [SearchComponent],
})
export class SearchInputModule {}
