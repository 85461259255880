import { ModelInterface } from "@interfaces/global/model.interface";

export interface PolicyInterface {
  id: string;
  type: string;
  language: string;
  filename: string;
  url: string;
}

export interface PoliciesInterface extends ModelInterface{
  policies: PolicyInterface[];
}

export interface PoliciesParsedInterface {
  [key: string]: {
    [key: string]: string
  }
}

export enum PolicyType {
  PRIVACY = 'PRIVACY',
  COOKIES = 'COOKIES',
  LEGAL_ADVERTISEMENT = 'LEGAL_ADVERTISEMENT',
  USER_TERMS_AND_CONDITIONS = 'USER_TERMS_AND_CONDITIONS',
  ACADEMY_TERMS_AND_CONDITIONS = 'ACADEMY_TERMS_AND_CONDITIONS',
  DATA_PROTECTION = 'DATA_PROTECTION',
  PRESS_RELEASE= 'PRESS_RELEASE',
}