import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';

Sentry.init(environment.sentry);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
    throw error;
  }
}
