import { Injectable } from '@angular/core';
import { CryptoInterface } from '@interfaces/crypto/crypto.interface';
import { ConfigsService } from '@services/config/config.service';
import { take } from 'rxjs';
import { createCipheriv } from 'crypto-browserify';

@Injectable()
export class CryptoService {
  private cryptoConfig: CryptoInterface;

  constructor(protected configService: ConfigsService) {
    this.configService
      .getConfigParam('crypto')
      .pipe(take(1))
      .subscribe((crypto) => (this.cryptoConfig = crypto));
  }

  cipher(value: string): string {
    const inputEncoding = this.cryptoConfig.cipher_input_encoding;
    const outputEncoding = this.cryptoConfig.cipher_output_encoding;

    const cipher = createCipheriv(
      this.cryptoConfig.algorithm,
      this.cryptoConfig.key,
      this.cryptoConfig.iv,
    );

    return cipher
      .update(value, inputEncoding, outputEncoding)
      .concat(cipher.final(outputEncoding));
  }
}
