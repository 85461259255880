import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GeolocationService } from './geolocation.service';

@NgModule({
  imports: [CommonModule],
})
export class GeolocationServiceModule {
  static forRoot(): ModuleWithProviders<GeolocationServiceModule> {
    return {
      ngModule: GeolocationServiceModule,
      providers: [GeolocationService],
    };
  }
}
