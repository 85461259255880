import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CategoryListModule } from '../category-list/category-list.module';
import { ErrorInputFormModule } from '../error-input-form/error-input-form.module';
import { SearchInputModule } from '../search-input/search-input.module';

import { UserModalComponent } from './user-modal/user-modal.component';
import { MenuModalComponent } from './menu-modal/menu-modal.component';

import { ToolbarComponent } from './toolbar.component';
import { LoginSlideComponent } from './user-modal/components/login/login.component';
import { SignupSlideComponent } from './user-modal/components/signup/signup.component';
import { ForgotPasswordSlideComponent } from './user-modal/components/forgot-password/forgot-password.component';
import { PreSignupComponent } from './user-modal/components/pre-signup/pre-signup.component';
import { SignupAcademySlideComponent } from './user-modal/components/signup-academy/signup-academy.component';
import { AgeSelectorModule } from '../age-selector/age-selector.module';
import { OnBoardingModalComponent } from './onboarding-modal/onboarding-modal.component';
import { RouterModule } from '@angular/router';
import { UsersServiceModule } from '@services/users/users.module';
import { AcademiesServiceModule } from '@services/academies/academies.module';
import { LanguageSwitcherModule } from '../language-switcher/language-switcher.module';
import { PoliciesServiceModule } from '@services/policies/policies.module';
import { LoadingModule } from '../loading/loading.module';
import { SwiperModule } from 'swiper/angular';
import { LogoutMessageModalModule } from '../logout-message-modal/logout-message-modal.module';
import { TippyDirective, provideTippyConfig } from '@ngneat/helipopper';
import { TippyProps } from '@ngneat/helipopper/lib/tippy.types';

const popoverMenu: Partial<TippyProps> = {
  arrow: true,
  interactive: true,
  interactiveDebounce: 100,
  trigger: 'click',
  theme: 'usermenu',
  placement: 'auto-end',
  animation: 'scale',
  zIndex: 99999,
};

@NgModule({
  imports: [
    TippyDirective,
    LogoutMessageModalModule,
    LoadingModule,
    PoliciesServiceModule,
    LanguageSwitcherModule,
    RouterModule,
    AgeSelectorModule,
    SwiperModule,
    CategoryListModule,
    AngularSvgIconModule.forRoot(),
    HttpClientModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ErrorInputFormModule,
    ReactiveFormsModule,
    SearchInputModule,
    IonicModule,
    UsersServiceModule,
    AcademiesServiceModule,
  ],
  declarations: [
    LoginSlideComponent,
    SignupSlideComponent,
    SignupAcademySlideComponent,
    ForgotPasswordSlideComponent,
    PreSignupComponent,
    ToolbarComponent,
    MenuModalComponent,
    UserModalComponent,
    OnBoardingModalComponent,
  ],
  exports: [ToolbarComponent],
  providers: [
    provideTippyConfig({
      defaultVariation: 'popoverMenu',
      variations: {
        popoverMenu,
      },
    }),
  ],
})
export class ToolbarModule {}
