import { Injectable } from '@angular/core';
import { ScheduleService } from './schedule.service';
import { ActivityInterface } from '@interfaces/activities/activities.interface';
import { SessionInterface } from '@interfaces/sessions/sessions.interface';

@Injectable()
export class CampScheduleFormatter {
  constructor(private scheduleService: ScheduleService) {}

  run(activity: ActivityInterface) {
    const availableSessions: SessionInterface[] = activity.sessions.filter(
      (session) => session.availablePlaces > 0,
    );
    const availableSessionsMonthDays = availableSessions.map((session) => {
      return session.schedules.map((schedule) => {
        return schedule.monthDay;
      });
    });
    let minDay;
    let maxDay;
    const uniqueSessionsMonthDays = [
      ...new Set(availableSessionsMonthDays.flat()),
    ];

    uniqueSessionsMonthDays.forEach((monthDay) => {
      const splittedMonthDay = monthDay.split('/');
      if (!minDay) minDay = splittedMonthDay[0];
      if (!maxDay) maxDay = splittedMonthDay[1];

      if (minDay && minDay > splittedMonthDay[0]) minDay = splittedMonthDay[0];
      if (maxDay && maxDay < splittedMonthDay[1]) maxDay = splittedMonthDay[1];
    });

    const startDay = this.scheduleService.parseDate(minDay);
    const endDay = this.scheduleService.parseDate(maxDay);

    return {
      startDay,
      endDay,
    };
  }
}
