import { Injectable } from '@angular/core';
import { AbstractService } from '@services/abstract/abstract.service';

@Injectable()
export class VisitsService extends AbstractService<any, any> {
  protected errorsSection = 'errors.visits';
  protected endPoint = '/activities/%s/visits/';

  public getStats(numOfMonths: number, section: string): void {
    const endPoint = '/visits/stats';
    this.getAll({ numOfMonths }, section, [], endPoint);
  }
}
