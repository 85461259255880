import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScheduleService } from './schedule.service';
import { CampScheduleFormatter } from './camp-schedule-formatter.service';
import { DayPlanScheduleFormatter } from './day-plan-schedule-formatter.service';
import { ExtracurricularScheduleFormatter } from './extracurricular-schedule-formatter.service';
import { BirthdayScheduleFormatter } from './birthday-schedule-formatter.service';

@NgModule({
  imports: [CommonModule],
})
export class ScheduleServiceModule {
  static forRoot(): ModuleWithProviders<ScheduleServiceModule> {
    return {
      ngModule: ScheduleServiceModule,
      providers: [
        ScheduleService,
        CampScheduleFormatter,
        DayPlanScheduleFormatter,
        ExtracurricularScheduleFormatter,
        BirthdayScheduleFormatter,
      ],
    };
  }
}
